import React, { memo } from "react";
import styles from "./YourReport.module.css";
import MapView from "../../../commonComponent/GoogleMap/MapView";
import { useTranslation } from "react-i18next";

const ReportDetailsRightMap = memo(
  ({ reportDetails, streetViewVisible, setStreetViewVisible }) => {
    const { t } = useTranslation();
    return (
      <>
        <div className={styles.googleMapRightMain}>
          <div className={styles.googleMapRight}>
            <MapView
              center={{
                lat: Number(reportDetails.latitude),
                lng: Number(reportDetails.longitude),
              }}
              zoom={16}
              mapContainerStyle={{
                height: "336px",
                width: "100%",
              }}
              reportDetails={reportDetails}
              markers={[reportDetails]}
              streetViewVisible={streetViewVisible}
            />
          </div>
          <button onClick={() => setStreetViewVisible(false)}>
            {t("MAP_VIEW")}
          </button>{" "}
          <span>|</span>
          <button onClick={() => setStreetViewVisible(true)}>
            {t("STREER_VIEW")}
          </button>
        </div>
      </>
    );
  }
);

export default ReportDetailsRightMap;
