import { Col, Container, Form, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./YourReport.module.css";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import commentIcon from "../../../assets/Images/Comment.svg";
import viewIcon from "../../../assets/Images/Views.svg";
import bookmarkIcon from "../../../assets/Images/Bookmark.svg";
import trendIcon from "../../../assets/Images/Trend.svg";
import UserSearchModule from "../../../commonComponent/UserSearchInput";
import CrudIcon from "../../../commonComponent/CrudIcon";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import SublyApi from "../../../helpers/Api";
import Loader from "../../../utils/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { formatTime } from "../../../utils/manageConstant";
import { isLimit, offset } from "../../../utils/Constants";
import DeletePopup from "./DeletePopup";
import {
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import defaultImage from "../../../assets/Images/defaultImg.webp";
import debounce from "lodash.debounce";
import LazyLoadImageComponent from "../../../utils/LazyLoading/LazyLoadImage";

const filterOptions = [
  { value: "viewbyDate", text: "View by Date" },
  { value: "mostViewed", text: "Most Viewed" },
  { value: "mostCommented", text: "Most Commented" },
  { value: "mostBookmarked", text: "Most Bookmarked" },
  { value: "mostTrending", text: "Most Trending" },
];

function YourReport() {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const { userToken, userData } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [showDataList, setShowDataList] = useState([]);
  const [totalReports, setTotalReports] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [reportid, setReportId] = useState();
  const [disable, setDisable] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const DeleteHandleShow = () => setDeleteShow(true);
  const DeleteHandleClose = () => setDeleteShow(false);

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const debouncedSearch = useCallback(
    debounce((text) => {
      SearchList(text);
    }, 500),
    []
  );

  useEffect(() => {
    if (debouncedSearchTerm) {
      debouncedSearch(debouncedSearchTerm);
    } else {
      userReport();
    }
    // Cleanup debounce on component unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearchTerm]);

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
    setDebouncedSearchTerm(searchText);
  };

  async function userReport(page = 1) {
    setLoader(true);
    await SublyApi.getUserReport(
      userToken,
      isLimit,
      offset + (page - 1) * isLimit
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response?.data?.reports;
        setTotalReports(response?.data?.total_reports);
        if (sortedReports && sortedReports.length > 0) {
          if (page === 1) {
            setShowDataList(sortedReports);
            setFilteredData(sortedReports);
          } else {
            setShowDataList((prev) => [...prev, ...sortedReports]);
            setFilteredData((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setShowDataList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  async function SearchList(text = "") {
    setLoader(true);
    await SublyApi.reportSearchList(
      userToken,
      isLimit,
      offset,
      true,
      true,
      false,
      true,
      true,
      true,
      true,
      text
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setShowDataList(response?.data?.reports);
        setTotalReports(response?.data?.total_reports);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  const applyFilter = (data, selectedCategory) => {
    let sortedData = [...data];
    if (selectedCategory === "viewbyDate") {
      sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
    } else if (selectedCategory === "mostViewed") {
      sortedData.sort((a, b) => b.view_count - a.view_count);
    } else if (selectedCategory === "mostCommented") {
      sortedData.sort((a, b) => b.comment_count - a.comment_count);
    } else if (selectedCategory === "mostBookmarked") {
      sortedData.sort((a, b) => b.bookmark_count - a.bookmark_count);
    } else if (selectedCategory === "mostTrending") {
      sortedData.sort((a, b) => b.trend_count - a.trend_count);
    }
    return sortedData;
  };

  const ChangeCategory = (e) => {
    const selectedCategory = e.target.value;
    setFilter(selectedCategory);
    setFilteredData(applyFilter(showDataList, selectedCategory));
    setShowDataList(applyFilter(showDataList, selectedCategory));
  };

  useEffect(() => {
    userReport();
  }, []);

  const ReportViewDetails = (id) => {
    navigate(`/report/${id}`);
  };

  const onToggleEdit = (item) => {
    navigate("/new-report", {
      state: {
        id: item.reportID,
        isUpdate: true,
      },
    });
  };

  const loadMoreReports = () => {
    setPage((prevPage) => prevPage + 1);
    userReport(page + 1);
  };

  const onToggleDelete = (id) => {
    DeleteHandleShow();
    setReportId(id);
  };

  return (
    <>
      <div className="main">
        {loader && <Loader />}
        <div className="spaceTopSet">
          <Container>
            <Row>
              <Col lg={3} md={4}>
                <Sidebar totalReports={totalReports} />
              </Col>

              <Col lg={9} md={8} className="rightPart">
                <div className="rightTopPart">
                  <h2>{t("YOUR_REPORT")}</h2>
                  <UserSearchModule hadleSearch={handleSearch} value={search} />
                </div>
                <div className={styles.datefilter}>
                  <div
                    className={`dropdown-container ${styles.dropdownContainer}`}
                  >
                    <Form.Select
                      className={`dropdown ${styles.inputSelect}`}
                      value={filter}
                      onChange={ChangeCategory}
                    >
                      {filterOptions &&
                        filterOptions.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                    </Form.Select>
                    <span className={`dropdown-icon ${styles.dropdownIcon}`}>
                      &#9660;
                    </span>
                  </div>
                </div>

                <div className={styles.scrollTopYourReport}>
                  {showDataList && showDataList.length > 0 ? (
                    showDataList.map((item, index) => (
                      <div className={styles.reportSection} key={index}>
                        <div
                          className={styles.reportSectionLeft}
                          onClick={() => ReportViewDetails(item.reportID)}
                        >
                          <div className={styles.reportImg}>
                            <LazyLoadImageComponent
                              src={
                                item?.profile_picture
                                  ? item?.profile_picture
                                  : defaultImage
                              }
                              alt="profile image"
                            ></LazyLoadImageComponent>
                          </div>
                          <div className={styles.reportText}>
                            <p>
                              <strong>{item.category_name}</strong>
                              {item.location != "undefined" && (
                                <> &nbsp; - &nbsp;</>
                              )}
                              {item.location == "undefined"
                                ? ""
                                : item.location}{" "}
                              &nbsp; - &nbsp;
                              <span>
                                {item.date} {t("At")}{" "}
                                {item.time
                                  ? formatTime(item.time)
                                  : t("UNKNOWN")}
                              </span>
                            </p>
                            <div className={styles.reportShow}>
                              <p>
                                <img src={commentIcon} alt="comment icon" />
                                &nbsp; &nbsp;
                                {item.comment_count == ""
                                  ? 0
                                  : item.comment_count}
                              </p>
                              <p>
                                <img src={viewIcon} alt="view icon" />
                                &nbsp; &nbsp;
                                {item.view_count == "" ? 0 : item.view_count}
                              </p>
                              <p>
                                <img src={bookmarkIcon} alt="bookmark icon" />
                                &nbsp; &nbsp;
                                {item.bookmark_count == ""
                                  ? 0
                                  : item.bookmark_count}
                              </p>
                              <p>
                                <img src={trendIcon} alt="trend icon" />
                                &nbsp; &nbsp;
                                {item.trend_count == "" ? 0 : item.trend_count}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.crudIconHover}>
                          <CrudIcon
                            commentId={item.reportID}
                            onToggleDelete={() => onToggleDelete(item.reportID)}
                            onToggleEdit={() => onToggleEdit(item)}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="records_Found">
                      <h2>{t("NO_REC_FOUND")}</h2>
                    </div>
                  )}

                  {showDataList?.length < totalReports ? (
                    <div className="loadMoreSection">
                      <button className="loadMoreBtn" onClick={loadMoreReports}>
                        {t("LOAD_MORE")}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DeletePopup
        deleteShow={deleteShow}
        DeleteHandleClose={DeleteHandleClose}
        reportid={reportid}
        setShowDataList={setShowDataList}
        setFilteredData={setFilteredData}
        userReport={userReport}
        showDataList={showDataList}
        disable={disable}
        setDisable={setDisable}
        setLoader={setLoader}
      />
    </>
  );
}

export default YourReport;
