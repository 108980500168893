import React, { memo } from "react";
import styles from "./YourReport.module.css";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatDateTimeSlider,
  formatTime,
} from "../../../utils/manageConstant";
import Slider from "react-slick";

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.arrrowLeftReportDetails} onClick={onClick}>
      <IoIosArrowBack />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.arrrowRightReportDetails} onClick={onClick}>
      <IoIosArrowForward />
    </div>
  );
};

const ReportDetailsSlider = memo(({ timeLineData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  console.log("timeLineData", timeLineData);

  // slider
  const settingsNew = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const TimeLineViewDetails = (id) => {
    navigate(`/report/${id}`);
  };

  return (
    <div>
      {timeLineData && timeLineData.length > 0 && (
        <>
          {/* slider */}
          {timeLineData && timeLineData.length > 1 ? (
            <div className={styles.reportDetailsSlider}>
              <div className={styles.reportSlider}>
                <Slider {...settingsNew}>
                  {timeLineData &&
                    timeLineData.length > 1 &&
                    timeLineData.map((item, index) => (
                      <div key={index} className={styles.reportSlide}>
                        {index % 2 === 0 ? (
                          <div className={styles.reportLeft}>
                            <div className={styles.reportInfo}>
                              <div
                                className={styles.reportText}
                                style={{ marginRight: "28px" }}
                                onClick={() =>
                                  TimeLineViewDetails(item.reportID)
                                }
                              >
                                <h3>{item.category_name}</h3>

                                <span className={styles.sliderFormateDate}>
                                  {item && item.date != "" ? (
                                    <>{formatDate(item && item.date)}</>
                                  ) : (
                                    ""
                                  )}

                                  {item && item.time != "" ? (
                                    <>
                                      &nbsp; <p>{t("At")} </p>&nbsp;
                                      {item && item.time
                                        ? formatTime(item.time)
                                        : t("UNKNOWN")}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <p>{item.location}</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className={styles.reportRight}>
                            <div className={styles.reportRightSliderText}>
                              <div className={styles.reportInfo}>
                                <div
                                  className={styles.reportText}
                                  onClick={() =>
                                    TimeLineViewDetails(item.reportID)
                                  }
                                >
                                  <h3>{item.category_name}</h3>
                                  <span className={styles.sliderFormateDate}>
                                    {item && item.date != "" ? (
                                      <>{formatDate(item && item.date)}</>
                                    ) : (
                                      ""
                                    )}
                                    {item && item.time != "" ? (
                                      <>
                                        &nbsp; <p>{t("At")} </p>&nbsp;
                                        {item && item.time
                                          ? formatTime(item.time)
                                          : t("UNKNOWN")}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  <p>{item.location}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          ) : (
            <div className={styles.reportAdds} style={{ mt: "10px" }}>
              <div
                onClick={() =>
                  TimeLineViewDetails(timeLineData && timeLineData[0]?.reportID)
                }
                style={{ mt: "10px" }}
              >
                <h3>xxx{timeLineData && timeLineData[0]?.category_name}</h3>
                <span>
                  {formatDateTimeSlider(
                    timeLineData && timeLineData[0]?.created_at
                  )}
                </span>
                <p>{timeLineData && timeLineData[0]?.location}</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default ReportDetailsSlider;
