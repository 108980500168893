import { t } from "i18next";
import { FiMapPin } from "react-icons/fi";
import { HiOutlineBell } from "react-icons/hi";
import { FiMenu } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { SlUser } from "react-icons/sl";
const currentYear = new Date().getFullYear();

export const HeaderData = [
  {
    id: 1,
    text: `${t("MAP")}`,
    icon: <FiMapPin />,
    link: "/map",
  },
  {
    id: 2,
    text: `${t("TIMELINE")}`,
    icon: <FiMenu />,
    link: "/timeline",
  },
  {
    id: 3,
    text: `${t("ALERT")}`,
    icon: <HiOutlineBell />,
    link: "/alerts",
  },
];

export const footerLinks = [
  {
    id: 1,
    link: "#",
    label: `${t("COPYRIGHT")}`,
    extraText: currentYear,
  },
  { id: 2, link: "/privacy-policy", label: `${t("PRIVACY")}` },
  { id: 3, link: "/terms-conditions", label: `${t("TERMS")}` },
  { id: 4, link: "/contactus", label: `${t("CONTACTUS")}` },
];

export const NetworksDashboardBox = [
  {
    id: 1,
    count: "15",
    text: "Reports",
  },
  {
    id: 2,
    count: "34",
    text: "Alerts",
  },
  {
    id: 3,
    count: "67",
    text: "Comments",
  },
  {
    id: 4,
    count: "0",
    text: "Alert Location",
  },
  {
    id: 5,
    count: <SlUser />,
    text: "Profile",
  },
  {
    id: 6,
    count: <IoSettingsOutline />,
    text: "Settings",
  },
];
