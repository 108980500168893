import { Col, Container, Form, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./LoginSettings.module.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { MdOutlineMailOutline } from "react-icons/md";
import InputGroups from "../../../commonComponent/InputGroup";
import { validateNumber, Validation } from "../../../utils/Validation";
import { ImCancelCircle } from "react-icons/im";
import { RiCheckboxCircleLine } from "react-icons/ri";
import Toster from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/statusCode";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../../helpers/Api";
import { useEffect, useState } from "react";
import AuthInput from "../../../commonComponent/AuthInputs";
import Loader from "../../../utils/Loader/Loader";
import AlertVerify from "./Modal/AlertVerify";
import { removeExtraPlusSigns } from "../../../commonComponent/ExtraPlus";
import {
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";

//---function for user login settings---------
function LoginSettings() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { userToken, currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [emailModified, setEmailModified] = useState(false);
  const [initialEmail, setInitialEmail] = useState("");
  const [verifyModal, setVerifyModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verifyType, setVerifyType] = useState("password");
  const [disable, setDisable] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneModified, setPhoneModified] = useState(false);

  // show value pre fill
  useEffect(() => {
    if (currentUser?.email) {
      setValue("email", currentUser.email);
      setInitialEmail(currentUser.email);
      setEmailModified(false);
    }
    if (currentUser?.phone_number) {
      setPhoneNo(currentUser.phone_number);
    }
    if (currentUser?.phone_dial_code) {
      setDialCode(currentUser.phone_dial_code);
    }
  }, [currentUser, setValue]);

  // disable space on key press input email field
  const onInputChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    setValue(e.target.name, value);
    if (e.target.name === "email") {
      setEmailModified(value !== initialEmail);
    }
  };

  // manage the type for email & number
  const handleContinueClick = (type) => {
    if (errors && errors.phoneNumber) {
      return false;
    }
    setVerifyType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onPhoneNumberChange = (e) => {
    setPhoneNo(e.target.value);
    setPhoneModified(true);
  };

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  // Login setting api
  const onSubmit = () => {
    let requestData = new FormData();
    const emailValue = getValues("email");

    if (verifyType === "email" || verifyType === "mobile_number") {
      requestData.append("mobile_number", phoneNo);
      requestData.append("dial_code", removeExtraPlusSigns(dialCode));
      requestData.append("country_code", countryCode);
      requestData.append("email", emailValue);
      setEmail(emailValue);
    }

    if (verifyType === "password") {
      requestData.append("email", getValues("email"));
      requestData.append("mobile_number", phoneNo ? phoneNo : "");
      requestData.append("new_password", getValues("newPassword"));
      requestData.append("confirm_password", getValues("confirmPassword"));
    }

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });

    setLoader(true);
    setDisable(true);
    closeModal();
    SublyApi.UpdateLoginData(dataObject, userToken, verifyType).then(
      (response) => {
        setLoader(false);
        setDisable(false);
        const msg = response && response.message;
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          Toster(t(msg), "success");
          setVerifyModal(true);
          setIsModalOpen(false);

          if (verifyType === "password") {
            setVerifyModal(false);
            setValue("newPassword", "");
            setValue("confirmPassword", "");
            dispatch(userLogoutClear());
            navigate("/");
          }
        } else if (
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "ACCOUNT_DISABLED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "SESSION_EXPIRED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
          Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
        } else {
          Toster(t(errormsg), "error");
        }
      }
    );
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <Sidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <h2>{t("LOGIN_SETTING")}</h2>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.loginSettings}>
                  <p>{t("LOGIN_TEXT")}</p>
                </div>
                <div className={styles.loginSettingsForm}>
                  <div className="loginWidth">
                    <InputGroups
                      icon={{
                        image: <MdOutlineMailOutline />,
                        color: "#d70303",
                      }}
                      name="email"
                      type="text"
                      register={register}
                      onInputChange={(e) => onInputChange(e, setValue)}
                      errors={errors}
                      validationRules={Validation.email}
                      label={t("EMAIL_ADDRESS_TEXT")}
                      placeholder={t("EMAIL_ADDRESS")}
                      isRightIcon={true}
                      text={""}
                      IconComponent={
                        !getValues("email") || emailModified
                          ? ImCancelCircle
                          : RiCheckboxCircleLine
                      }
                      iconColor={
                        !getValues("email") || emailModified
                          ? "#FF0000"
                          : "#28A84B"
                      }
                    />
                    <button
                      className={styles.continueBtn}
                      onClick={() => handleContinueClick("email")}
                    >
                      {t("CONTINUE")}
                    </button>

                    <div style={{ position: "relative" }}>
                      <AuthInput
                        name="phoneNumber"
                        type="number"
                        control={control}
                        errors={errors}
                        label={t("MOBILE_NUMBER")}
                        placeholder={t("ENTER_CONTACT")}
                        phoneInputProps={{
                          country: "za",
                          countryCodeEditable: false,
                          dialCode,
                          phoneNo,
                          setCountryCode,
                          setDialCode,
                          setPhoneNo,
                          clearErrors,
                        }}
                        validate={(phone) => validateNumber(phone)}
                        onChange={onPhoneNumberChange}
                      />
                      {!phoneNo || phoneModified ? (
                        <ImCancelCircle className="crossIcon" />
                      ) : (
                        <RiCheckboxCircleLine className="checkIcon" />
                      )}
                      <button
                        className={styles.continueBtn}
                        onClick={() => handleContinueClick("mobile_number")}
                        disabled={!phoneNo}
                      >
                        {t("CONTINUE")}
                      </button>
                    </div>
                    <h5>{t("REVERIFY")}</h5>
                  </div>
                </div>
                <div className={styles.loginSettingsForm}>
                  <h2>{t("PASSWORD")}</h2>
                </div>
                <div className={styles.loginSettingsForm}>
                  <div>
                    <p>{t("PASS_LIMIT")}</p>
                    <InputGroups
                      name="newPassword"
                      type="password"
                      register={register}
                      onInputChange={(e) => onInputChange(e, setValue)}
                      errors={errors}
                      validationRules={Validation.password}
                      label={t("NEW_PASS")}
                      placeholder={t("ENTER_PASSWORD")}
                      maxCount={""}
                      text={""}
                      isHideGroup
                      isRequired={false}
                    />

                    <InputGroups
                      name="confirmPassword"
                      type="password"
                      register={register}
                      onInputChange={(e) => onInputChange(e, setValue)}
                      errors={errors}
                      validationRules={Validation.confirmPassword}
                      label={t("CONFIRM_PASSWORD")}
                      placeholder={t("ENTER_PASSWORD")}
                      maxCount={""}
                      text={""}
                      isHideGroup
                      isRequired={false}
                      validate={(value) =>
                        value === watch("newPassword") || t("PASS_NOT_MATCH")
                      }
                    />
                  </div>

                  <button
                    className={styles.continueBtn}
                    type="submit"
                  >
                    {t("SAVE_CHANES")}
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <AlertVerify
        onClose={closeModal}
        showModal={isModalOpen}
        verifyType={verifyType}
        callApi={onSubmit}
        verifyModal={verifyModal}
        setVerifyModal={setVerifyModal}
        email={email}
        phoneNo={phoneNo}
        dialCode={dialCode}
        countryCode={countryCode}
        disable={disable}
        setVerifyType={setVerifyType}
      />
    </div>
  );
}

export default LoginSettings;
