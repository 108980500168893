import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { useTranslation } from "react-i18next";
import Toster from "../../utils/Toaster";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  initializeCheckboxes,
  setSearchCheckboxesQuery,
  setSearchCountApi,
} from "../../store/slices/Userslice";

//----------function for serach filter design--------------
const SearchFilter = ({ toggleTooltipSearch, setShowJoin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    searchFilterQuery,
    searchCountApi,
    searchCheckboxesQuery,
    userToken,
  } = useSelector((state) => state.user);
  const initialCheckboxes = [
    { id: "crimeReport", label: "Crime Report", checked: true },
    { id: "suspiciousActivity", label: "Suspicious Activity", checked: true },
    { id: "peopleProfiles", label: "People and Profiles", checked: true },
    { id: "yourReports", label: "Your Reports", checked: true },
    { id: "bookmarkedReports", label: "Bookmarked Reports", checked: true },
    { id: "missingPersons", label: "Missing Persons", checked: true },
    { id: "wantedPersons", label: "Wanted Persons", checked: true },
  ];

  const [checkboxes, setCheckboxes] = useState(
    searchCheckboxesQuery.length ? searchCheckboxesQuery : initialCheckboxes
  );

  useEffect(() => {
    dispatch(setSearchCheckboxesQuery(checkboxes));
  }, [checkboxes]);

  useEffect(() => {
    if (searchCheckboxesQuery.length === 0) {
      dispatch(initializeCheckboxes(initialCheckboxes));
    }
  }, [dispatch, searchCheckboxesQuery]);

  const handleCheckboxChange = (event, id) => {
    const { checked } = event.target;
    setCheckboxes((prevState) =>
      prevState.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked } : checkbox
      )
    );
  };

  const handleSelectAll = () => {
    setCheckboxes((prevState) =>
      prevState.map((checkbox) => ({ ...checkbox, checked: true }))
    );
  };

  const handleSelectNone = () => {
    setCheckboxes((prevState) =>
      prevState.map((checkbox) => ({ ...checkbox, checked: false }))
    );
  };

  const handleSearch = () => {
    if (userToken) {
      if (searchFilterQuery) {
        toggleTooltipSearch();
        dispatch(setSearchCheckboxesQuery(checkboxes));
        dispatch(setSearchCountApi(searchCountApi + 1));
        navigate("/search-result");
      } else {
        Toster(t("WANT_SEARCH"), "error");
        return;
      }
    } else {
      setShowJoin(true);
    }
  };

  return (
    <>
      <div className={`${styles.tooltipMianDiv} ${styles.tooltipMianDivS}`}>
        <div className={styles.tooltipTopBackground}></div>
        <div className={styles.tooltipUserName}>
          {checkboxes.map((key) => (
            <div className={styles.displayOptionss} key={key}>
              <Form.Check
                type="checkbox"
                id={key}
                className="customCheckbox"
                checked={key.checked}
                onChange={(e) => handleCheckboxChange(e, key.id)}
              />
              <p>{key.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.bottomSelect}>
          <span className={styles.bottomSelectLeft} onClick={handleSelectAll}>
            {t("SELECT_ALL")}
          </span>
          <span onClick={handleSelectNone}> {t("SELECT_NONE")} </span>
        </div>
        <div className={styles.conformBtns}>
          <Button type="submit" onClick={handleSearch}>
            {t("SEARCH")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
