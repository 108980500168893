import { useState } from "react";
import axios from "axios";
import Loader from "../../utils/Loader/Loader";
import { FaXTwitter } from "react-icons/fa6";
import styles from "../Login/Login.module.css";

//---------function for twitter social api call----------
function TwitterSocial({ twitterLoginFrom }) {
  const [loader, setLoader] = useState(false);
  //required common header for each api calling.
  const commonHeaders = {
    "Device-Type": 1,
    "Device-Id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID),
    Timezone: "Asia/Kolkata",
  };
  const handleTwitterAuth = async () => {
    setLoader(true);
    try {
      const requestTokenResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}twitter/requestToken`,
        { headers: commonHeaders }
      );
      const oauthToken = requestTokenResponse.data.data.result.oauth_token;
      const oauth_token_secret =
        requestTokenResponse.data.data.result.oauth_token_secret;
      localStorage.setItem("oauth_token_secret", oauth_token_secret);
      localStorage.setItem("twitterLoginFrom", twitterLoginFrom);
      // Redirect user to Twitter for authentication
      window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauthToken}`;
    } catch (error) {
      console.error("Error obtaining request token:", error);
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="socialButton">
        <button onClick={handleTwitterAuth} className={styles.socialDiv}>
          <FaXTwitter />
        </button>
      </div>
    </>
  );
}
export default TwitterSocial;
