import Modal from "react-bootstrap/Modal";
import styles from "../YourReport/YourReport.module.css";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import Security_icon from "../../../assets/Images/Security_announcement.svg";
import SublyApi from "../../../helpers/Api";
import Toster from "../../../utils/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/statusCode";
import {
  updateSettingCount,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";

function AlertClearPopup({
  DeleteHandleAlertClose,
  deleteAlertShow,
  setAlertList,
  getAlertsDetails,
  disable,
  // setDisable,
  setLoader,
}) {
  const { userToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function ClearAlertsList() {
    // setDisable(true);
    setLoader(true);
    DeleteHandleAlertClose();
    await SublyApi.ClearAlerts(userToken).then((response) => {
      // setDisable(false);
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (
        response &&
        response.data &&
        response.status_code === STATUS_CODES.SUCCESS
      ) {
        getAlertsDetails();
        setAlertList();
        DeleteHandleAlertClose();

        const count = 0;
        dispatch(
          updateSettingCount({
            keyName: "total_alerts",
            value: count,
          })
        );
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  return (
    <>
      <Modal
        className="mainModule"
        show={deleteAlertShow}
        onHide={() => {
          DeleteHandleAlertClose();
        }}
      >
        <div className="MainBox">
          <div className="header">
            <span className="alertCross">
              <RxCross2
                onClick={() => {
                  DeleteHandleAlertClose();
                }}
              />
            </span>
          </div>
          <div className="hadingMainDiv">
            <div className={styles.securityIconDiv}>
              <span>
                <img src={Security_icon} alt="icon"/>
              </span>
            </div>

            <div className={styles.deleteHeading}>
              <h2>{t("CONFIRM_CLEAR")}</h2>
              <p>{t("CONFIRM_DELETE_MESSAGE")}</p>
            </div>

            <div className={styles.deleteBtn}>
              <button
                className={styles.deleteContinue}
                onClick={() => {
                  ClearAlertsList();
                }}
                type="submit"
                disabled={disable}
              >
                {" "}
                {t("CONTINUE")}
              </button>
              <button
                className={styles.deleteCancel}
                onClick={() => {
                  DeleteHandleAlertClose();
                }}
                type="button"
              >
                {" "}
                {t("CANCEL")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AlertClearPopup;
