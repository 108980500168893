import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NetWorksSidebar from "../NetWorksSidebar";

//---function for user dashboard---------
function NetworksCrimeState() {
  const { t } = useTranslation();
  return (
    <div className="main">
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <NetWorksSidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <h2>{t("NETWORK_MESSAGES")}</h2>
              </div>
              <div className="dashboardInfo "></div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default NetworksCrimeState;
