import Slider from "@mui/material/Slider";
import { useTranslation } from "react-i18next";

//--------Common distance slider for user module----------
function SliderAge({ min, max, value, onChange }) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="sliderDate">
        <strong>{value}</strong>
        <h6>AGE</h6>
      </div>

      <div className="SliderSetMui">
        <p style={{ marginRight: "20px" }}>0</p>
        <Slider
          aria-label="Default"
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          className="custom-slider"
        />
        <p style={{ marginLeft: "20px" }}>99</p>
      </div>
    </div>
  );
}

export default SliderAge;
