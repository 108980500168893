import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./getCroppedImg";
import Modal from "react-bootstrap/Modal";
import styles from "./CropImg.module.css";
import { useTranslation } from "react-i18next";
function CropImg({
  handleClose,
  show,
  imageSrc,
  setSelectedImage,
  aspectRatio,
  setIsCropper,
  setImageSrc,
  onImageChange,
}) {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 1, y: 1 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const croppedImageData = await getCroppedImg(imageSrc, croppedAreaPixels);
      setSelectedImage(croppedImageData);
    },
    [imageSrc]
  );

  return (
    <Modal
      show={show}
      className={styles.ModalMainBox}
      onHide={() => {
        handleClose();
        setSelectedImage();
        setIsCropper(false);
        setImageSrc();
      }}
    >
      <Modal.Header className={styles.ModalHeader} closeButton>
        <Modal.Title>
          <p>
            {t("CROP")} <span>{t("IMAGE")}</span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.ModalBody}>
        <div>
          {imageSrc && (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              cropShape={"rect"}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.ModalFooter}>
        <button
          onClick={() => {
            onImageChange();
          }}
        >
          <p>{t("CROP")}</p>
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CropImg;
