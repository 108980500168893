import React from 'react'
import styles from "../UserModule/YourReport/YourReport.module.css";
import AdBannerRight from "../../assets/Images/336_In-Detail.gif";
import LazyLoadImageComponent from '../../utils/LazyLoading/LazyLoadImage';

const AdBannerRightSmall = () => {
  return (
    <div className={styles.reportRightAd}>
    <a href="https://www.in-detail.com" rel="noopener noreferrer">
      <LazyLoadImageComponent
        src={AdBannerRight}
        width="100%"
        height="280px"
        style={{ objectFit: "cover" }}
        alt="adBannert image"
      />
    </a>
  </div>
  )
}

export default AdBannerRightSmall