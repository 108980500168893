import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/Images/logo.svg";
import styles from "../Footer/Footer.module.css";
import appStore from "../../assets/Images/appStore.svg";
import googlePlay from "../../assets/Images/googlePlay.svg";
import AppGallery from "../../assets/Images/AppGallery.svg";
import { useTranslation } from "react-i18next";
import { footerLinks } from "../../mockdata";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";

//---------function for footer----------
function Footer() {
  const { t } = useTranslation();

  const GOOGLE_PLAY_LINK = "https://play.google.com/store/apps/details?id=com.crimespotter&pli=1";
const APP_STORE_LINK = "https://apps.apple.com/za/app/crimespotter/id1518453242";
const APP_GALLERY_LINK = "https://appgallery.huawei.com/#/app/C102602347";

  return (
    <section className={styles.footerSection}>
      <Container>
        <Row style={{ alignItems: "center" }}>
          <Col lg={4}>
            <img src={logo} alt="logo" />
          </Col>
          <Col lg={5}>
            <div className={styles.appSection}>
              <a href={GOOGLE_PLAY_LINK}>
                <img src={googlePlay} alt="googlePlay" />
              </a>

              <a href={APP_STORE_LINK}>
                <img src={appStore} alt="appStore" />
              </a>

              <a href={APP_GALLERY_LINK}>
                <img
                  src={AppGallery}
                  alt="AppGallery"
                  className={styles.AppGallery}
                />
              </a>
            </div>
          </Col>
          <Col lg={3}>
            <div className={styles.textFooter}>
              <NavLink to="/how-it-works">{t("HOW_WORKS")}</NavLink>
              <br></br>
              <NavLink to="/how-it-works">{t("JOIN_THIS")}</NavLink>
            </div>
          </Col>
        </Row>
        <div className={styles.footerLinks}>
          {footerLinks.map((item, index) => (
            <Fragment key={index}>
              <NavLink to={item.link}>
                {t(item.label)} {item.extraText || ""}
              </NavLink>
              <span className={styles.verticleLine}></span>
            </Fragment>
          ))}
          <p>{t("DESIGN_ADVERTISE")}</p>
        </div>
      </Container>
    </section>
  );
}
export default Footer;
