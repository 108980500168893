import React, { memo, useEffect, useState } from "react";
import styles from "./YourReport.module.css";
import TrendRed from "../../../assets/Images/TrendRed.svg";
import BookmarkRed from "../../../assets/Images/BookmarkRed.svg";
import { active, inactive } from "../../../utils/Constants";
import { useTranslation } from "react-i18next";
import ShareLink from "./ShareLink";
import branch from "branch-sdk";
import { v4 as uuidv4 } from "uuid";

const ReportDetailsShare = memo(
  ({ TrendCount, reportDetails, BookmarkCount, routeName }) => {
    const { t } = useTranslation();

    const [branchInitialize, setBranchInitialize] = useState(false);
    const [generateUrl, setGenerateUrl] = useState("");

    useEffect(() => {
      branch.init(process.env.REACT_APP_BRANCH_IO_KEY, (err, data) => {
        if (err) {
          console.error("Branch initialization error:", err);
        } else {
          setBranchInitialize(true);
        }
      });
    }, []);

    const generateUniqueAlias = () => {
      var uniqueId = uuidv4().replace(/-/g, "").substring(0, 5);
      return `report/${uniqueId}`;
    };

    const generateDeepLink = () => {
      const linkData = {
        channel: "email",
        feature: "referral",
        alias: generateUniqueAlias(),
        data: {
          $fallback_url: `https://www.crimespotter.app${routeName}`,
        },
      };

      branch.link(linkData, (err, link) => {
        if (err) {
          console.error("Branch link error:", err);
        } else {
          setGenerateUrl(link);
        }
      });
    };

    useEffect(() => {
      if (branchInitialize) {
        generateDeepLink();
      }
    }, [branchInitialize]);
    return (
      <>
        <div className={styles.reportSocialIcon}>
          <span
            onClick={() => {
              TrendCount(
                reportDetails && reportDetails.reportID,
                reportDetails && reportDetails.is_trended == active
                  ? inactive
                  : active
              );
            }}
          >
            {reportDetails.is_trended == active ? (
              <>
                <img src={TrendRed} alt="trend icon" />
                &nbsp; <div style={{ color: "red" }}>{t("TREND_MARKED")}</div>
              </>
            ) : (
              <>
                <>
                  <svg
                    className={styles.trendIcon}
                    width="17px"
                    height="16px"
                    viewBox="0 0 17 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <title>{t("GROUP")}</title>
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <g
                        id="USER_REPORTS"
                        transform="translate(-613, -525)"
                        stroke="currentColor"
                        strokeWidth="1.56"
                      >
                        <g id="Group" transform="translate(614.52, 526)">
                          <path
                            d="M6.916,13.832 C3.09639867,13.832 0,10.7356013 0,6.916 C0,3.09639867 3.09639867,0 6.916,0 C10.7356013,0 13.832,3.09639867 13.832,6.916 C13.832,10.7356013 10.7356013,13.832 6.916,13.832 Z"
                            id="Path"
                          ></path>
                          <polygon
                            id="Path"
                            transform="translate(6.9822, 7.7102) rotate(-315) translate(-6.9822, -7.7102)"
                            points="5.096 5.824 8.86836364 5.824 5.096 5.824 5.096 9.59636364"
                          ></polygon>
                        </g>
                      </g>
                    </g>
                  </svg>
                </>
                &nbsp; {t("TREND_COUNT")}
              </>
            )}
          </span>
          <span
            onClick={() => {
              BookmarkCount(
                reportDetails && reportDetails.reportID,
                reportDetails && reportDetails.is_bookmarked == active
                  ? inactive
                  : active
              );
            }}
          >
            {reportDetails.is_bookmarked == active ? (
              <>
                <img src={BookmarkRed} alt="bookmark icon" />
                &nbsp;
                <div style={{ color: "red" }}>{t("BOOKMARK_MARKED")}</div>
              </>
            ) : (
              <>
                <>
                  <svg
                    className={styles.icon}
                    width="15px"
                    height="15px"
                    viewBox="0 0 15 15"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <title>{t("ICON")}</title>
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <g
                        id="USER_REPORTS"
                        transform="translate(-552, -526)"
                        stroke="currentColor"
                        strokeWidth="1.56"
                      >
                        <path
                          d="M562.668571,532.982857 L562.668571,530.011429 L562.668571,532.982857 L559.697143,532.982857 L562.668571,532.982857 Z M562.668571,532.982857 L562.668571,535.954286 L562.668571,532.982857 L565.64,532.982857 L562.668571,532.982857 Z M562.668571,539.52 L557.914286,535.657143 L553.16,539.52 L553.16,527.04 L562.668571,527.04"
                          id="Icon"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </>
                &nbsp;{t("BOOKMARK_COUNT")}
              </>
            )}
          </span>
          <>
            <ShareLink
              className={styles.shareLinkText}
              generateUrl={generateUrl}
            />
          </>
        </div>
      </>
    );
  }
);

export default ReportDetailsShare;
