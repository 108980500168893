import { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./modal.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import HeaderButton from "../../../../commonComponent/CommonButton/HeaderButton";
import SublyApi from "../../../../helpers/Api";
import { STATUS_CODES } from "../../../../utils/statusCode";
import Loader from "../../../../utils/Loader/Loader";
import { userLogoutClear } from "../../../../store/slices/Userslice";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../../../utils/Toaster";
import { Validation } from "../../../../utils/Validation";
import { handleKeyDown } from "../../../../utils/manageConstant";
import { removeExtraPlusSigns } from "../../../../commonComponent/ExtraPlus";

//---------function for verify OTP modal----------
function VerifyOtp({
  verifyModal,
  setVerifyModal,
  email,
  verifyType,
  phoneNo,
  dialCode,
  countryCode,
  setVerifyType,
  callApi,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { userToken } = useSelector((state) => state.user);

  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  //----------function for verify account api calling----------
  const onSubmit = async (formData) => {
    let requestData = new FormData();
    requestData.append("verification_code", otp);
    if (verifyType === "email") {
      requestData.append("email", email);
    } else {
      requestData.append("mobile_number", phoneNo);
      requestData.append("dial_code", removeExtraPlusSigns(dialCode));
      requestData.append("country_code", countryCode);
    }
    setLoader(true);
    await SublyApi.verifyAccount(requestData, userToken).then((response) => {
      setLoader(false);
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t(msg), "success");
        reset();
        setVerifyModal(false);
        setOtp("");
        dispatch(userLogoutClear());
        navigate("/");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  const handleContinue = () => {
    callApi();
  };

  return (
    <>
      {loader && <Loader />}
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={verifyModal}
          onHide={() => {
            setVerifyModal(false);
          }}
          className={`modalDialog ${styles.modalClass}`}
          backdrop="static"
        >
          <Modal.Header className={styles.modalHeader}>
            <h4>
              {verifyType === "email" ? t("VERIFY_EMAIL") : t("VERIFY_PHONE")}
            </h4>
          </Modal.Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body className={styles.modalBody}>
              <h5>
                {verifyType === "email" ? t("CHECK_EMAIL") : t("CHECK_NUMBER")}
              </h5>

              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={t("ENTER_OTP")}
                  {...register("otp", Validation.otp)}
                  value={otp}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
                {errors.otp && (
                  <span className="errorMsg">{errors.otp.message}</span>
                )}
              </Form.Group>
              <p>
                {t("DIDNT_RECIVE")}
                <NavLink onClick={handleContinue}> {t("RESEND_OTP")}</NavLink>
              </p>
            </Modal.Body>
            <Modal.Footer className={styles.modalButton}>
              <Button
                type="button"
                className={styles.leftButton}
                onClick={() => {
                  setVerifyModal(false);
                  setVerifyType("password");
                }}
              >
                {t("CANCEL")}
              </Button>
              <HeaderButton type="submit" label={t("VERIFY_OTP")} />
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  );
}
export default VerifyOtp;
