import moment from "moment";
// manage key buttons down
export const handleKeyDown = (e) => {
  const isNumericKey =
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    (e.keyCode >= 96 && e.keyCode <= 105);

  const isBackspace = e.keyCode === 8;

  if (!isNumericKey && !isBackspace) {
    e.preventDefault();
  }

  // Restrict the input length to a maximum of 5 characters
  if (e.target.value.length >= 5 && !isBackspace) {
    e.preventDefault();
  }
};

// disable space on key press input email field
export const onInputChange = (e, setValue) => {
  setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
};

// disable space on key press input
export const handleKeyPress = (e, setValue) => {
  if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
};

export const allowCharLimit = (event, setValue) => {
  const value = event.target.value;

  // Prevent leading spaces
  if (value === "" && event.key === " ") {
    event.preventDefault(); // Prevent the first character from being a space
  }

  // Prevent input beyond 50 characters
  if (value.length >= 50 && event.key !== "Backspace") {
    event.preventDefault(); // Prevent further input if the character limit is reached
  } else {
    setValue(value); // Allow valid input within the limit
  }
};

// ----formate date and time
export const formatTime = (time) => {
  if (time === "00:00:00" || time === "Unknown Time" || time == "") {
    return "Unknown Time";
  }
  const [hours, minutes] = time.split(":");
  return `${hours}:${minutes}`;
};

export const formatDateTimeSlider = (dateString) => {
  const date = new Date(dateString);
  const options = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  const formattedDate = date.toLocaleDateString("en-GB", options);

  // Adjusting to the desired format "DD Month YYYY at HH:MM"
  const parts = formattedDate.split(", ");
  return parts;
};

// ---report details show date year
export function formatDate(dateString) {
  const date = new Date(dateString);

  // Array of weekday names
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // Array of month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const weekday = weekdays[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${weekday} ${day} ${month} ${year}`;
}

// ---dashbard last login time formate
export function LastLoginDateTime(timestamp) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// ---chat time
export const formatUpdatedTime = (date, timezone = moment.tz.guess()) => {
  const localDateTime = moment.utc(date).tz(timezone);
  return localDateTime.fromNow();
};

export const formatUpdatedDate = (date, timezone = moment.tz.guess()) => {
  const localDateTime = moment.utc(date).tz(timezone);
  return localDateTime.format("DD MMMM YYYY - HH:mm");
};

// convert image in binary
export const b64toBlob = (cropData, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  var byteCharacters = window.atob(cropData); //decode string
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getRandom = () => {
  return Math.floor(Math.random() * 9000000000) + 1000000000;
};

const handleExtractFrame = (videoFile, setVideoThumb) => {
  if (!videoFile) return;

  const videoElement = document.createElement("video");
  videoElement.src = URL.createObjectURL(videoFile);
  videoElement.crossOrigin = "anonymous";
  videoElement.muted = true; // Mute the video for autoplay
  videoElement.play(); // Start playing the video to ensure metadata is loaded
  videoElement.onloadeddata = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const ctx = canvas.getContext("2d");
    // Ensure the video is loaded and can be drawn
    videoElement.currentTime = 0; // Set to the first frame

    videoElement.onseeked = () => {
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(async (blob) => {
        // Send the image blob to the API
        const requestData = new FormData();
        requestData.append("attachment_thumb", blob, "frame.jpg");
        setVideoThumb(requestData.get("attachment_thumb"));
        return requestData.get("attachment_thumb");
      }, "image/jpeg");
    };
  };
};

export function convertToNumber(value) {
  if (!value) {
    return 0; // Handle falsy values (false, 0, '', null, undefined, NaN)
  }

  const number = Number(value);

  if (isNaN(number)) {
    return 0;
  }

  return number;
}

export const capitalizeFirstWord = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

// local time show
export const formatLocalTime = (dateString) => {
  // Convert the UTC date string to local time using Moment.js
  const localDateString = moment
    .utc(dateString)
    .local()
    .format("YYYY-MM-DD HH:mm:ss");
  return localDateString;
};
