import React, { memo, useState } from "react";
import styles from "./YourReport.module.css";
import { useTranslation } from "react-i18next";
import { formatDate, formatTime } from "../../../utils/manageConstant";
import { SlEye } from "react-icons/sl";
import { FaRegFlag } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import FlagPopup from "./Flag/FlagPopup";
import FlagClosePopup from "./Flag/FlagClosePopup";
import { ChatTextBrTageFormate, FIELD_TYPE } from "../../../utils/Constants";
import { FaRegCircleCheck } from "react-icons/fa6";

const ReportDetailsText = memo(
  ({
    reportDetails,
    currentUser,
    handleEdit,
    detailsWithHashtags,
    containerRef,
  }) => {
    // --flag popup show
    const [flagShow, setFlagShow] = useState(false);
    const flagPopupHandleShow = () => setFlagShow(true);
    const flagPopupHandleClose = () => setFlagShow(false);

    // --thanksflagShow popup show
    const [thanksflagShow, setThanksflagShow] = useState(false);
    const ThanksflagHandleShow = () => setThanksflagShow(true);
    const ThanksflagHandleClose = () => setThanksflagShow(false);

    const { t } = useTranslation();
    return (
      <>
        <div className={styles.reportLeftSide}>
          <div className={styles.reportDetText}>
            <h1>{reportDetails && reportDetails.category_name}</h1>
            <span>
              {formatDate(reportDetails && reportDetails.date)}&nbsp;{" "}
              <p>{t("At")} </p>&nbsp;
              {reportDetails && reportDetails.time
                ? formatTime(reportDetails.time)
                : t("UNKNOWN")}
            </span>
            <h3>
              {reportDetails && reportDetails.location !== "undefined"
                ? reportDetails.location
                : ""}
            </h3>
          </div>

          <div className={styles.reportIconData}>
            <span>
              <SlEye />
              <p>{reportDetails && reportDetails.view_count}</p>
            </span>
            <span>
              <p>
                {t("REPORT")} #{reportDetails && reportDetails.reportID}
              </p>
            </span>
            {reportDetails.reported_by_id &&
              reportDetails.reported_by_id !== currentUser.userID && (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    flagPopupHandleShow(true);
                  }}
                >
                  <FaRegFlag />
                  <p>{t("FLAG_REPORT")}</p>
                </span>
              )}
            {reportDetails.reported_by_id &&
              reportDetails.reported_by_id == currentUser.userID && (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleEdit(reportDetails);
                  }}
                >
                  <FaRegEdit />
                  <p>{t("EDITS")}</p>
                </span>
              )}
          </div>
          <div className={styles.reportMainDesc}>
            <p>
              {detailsWithHashtags && (
                <span
                  style={{ cursor: "pointer" }}
                  ref={containerRef}
                  dangerouslySetInnerHTML={{
                    __html: ChatTextBrTageFormate(detailsWithHashtags),
                  }}
                />
              )}
            </p>
          </div>
        </div>

        {reportDetails && reportDetails.field_type == FIELD_TYPE.PERSON && (
          <>
            <div className={styles.persionReportDetails}>
              <h2>{reportDetails && reportDetails.person_report.full_name}</h2>
              <span>
                {reportDetails && reportDetails.person_report && (
                  <>
                    {reportDetails.person_report.gender == 0
                      ? ""
                      : reportDetails.person_report.gender == 1
                      ? `${t("MALE")}`
                      : `${t("FEMALE")}`}
                    {reportDetails.person_report.age != 0 &&
                      ` - ${reportDetails.person_report.age}`}
                    {reportDetails.person_report.ethinicity_name &&
                      ` - ${reportDetails.person_report.ethinicity_name}`}
                  </>
                )}
              </span>
            </div>

            {reportDetails && reportDetails.person_report.is_found == 1 && (
              <div className={styles.persionFound}>
                <FaRegCircleCheck />
                <span>{t("FOUND")}</span>
              </div>
            )}
          </>
        )}

        {reportDetails && reportDetails.field_type == FIELD_TYPE.VEHICLE && (
          <>
            <div className={styles.vehicleReportDetails}>
              <div className={styles.persionReportDetails}>
                <h2>
                  {reportDetails && reportDetails.vehicle_report.registration}
                </h2>
                <span>
                  {reportDetails &&
                    reportDetails.vehicle_report &&
                    [
                      reportDetails.vehicle_report.vehicle_make,
                      reportDetails.vehicle_report.model,
                      reportDetails.vehicle_report.color,
                    ]
                      .filter(Boolean)
                      .join(" - ")}
                </span>
              </div>
            </div>
          </>
        )}

        <FlagPopup
          flagShow={flagShow}
          flagPopupHandleClose={flagPopupHandleClose}
          reportDetails={reportDetails}
          ThanksflagHandleShow={ThanksflagHandleShow}
          setThanksflagShow={setThanksflagShow}
        />

        <FlagClosePopup
          thanksflagShow={thanksflagShow}
          ThanksflagHandleClose={ThanksflagHandleClose}
        />
      </>
    );
  }
);

export default ReportDetailsText;
