import { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  Tooltip,
  Overlay,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import styles from "./MapList.module.css";
import { IoSearchSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import Toster from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/statusCode";
import { MdArrowDropDown } from "react-icons/md";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import MultipleCategoryModal from "./MultipleCategoryModal";
import CurrentLocationImg from "../../assets/Images/Combined Shape.svg";
import { LOCATION } from "../../utils/Constants";
import { userDetailData, userLogoutClear } from "../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import MapViewZoom from "../../commonComponent/GoogleMap/MapviewZoom";

function MapList() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
  });

  const [autoCompleteLoad, setAutoCompleteLoad] = useState(null);
  const onLoad = (autocompleteInstance) => {
    setAutoCompleteLoad(autocompleteInstance);
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state.user);
  const autocomplete = useRef(null);
  const [mapList, setMapList] = useState("");
  const [center, setCenter] = useState({
    location: "",
    lat: 0,
    lng: 0,
    countryName: LOCATION.COUNTRY_NAME,
  });

  const [currentLocation, setCurrentLocation] = useState({
    location: "",
    lat: 0,
    lng: 0,
    countryName: "",
  });

  const [getCurrentPosition, setCurrentPosition] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (getCurrentPosition.lat && getCurrentPosition.lng) {
      getGeocode(getCurrentPosition.lat, getCurrentPosition.lng);
    }
  }, [getCurrentPosition, isLoaded]);

  const [searchAddress, setSearchAddress] = useState("");

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [crimesCategory, setCrimesCategory] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(89, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [startDateSelect, setStartDateSelect] = useState(
    startDate.format("YYYY-MM-DD")
  );
  const [endDateSelect, setEndDateSelect] = useState(
    endDate.format("YYYY-MM-DD")
  );

  const [selectedRange, setSelectedRange] = useState("");
  const [mapHeight, setMapHeight] = useState(window.innerHeight - 352);

  useEffect(() => {
    const handleResize = () => {
      setMapHeight(window.innerHeight - 350);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  const getGeocode = async (latitude, longitude) => {
    const { google } = window;
    if (isLoaded && google && latitude && longitude) {
      const geocoder = new google.maps.Geocoder();
      const latLng = new google.maps.LatLng(latitude, longitude);
      try {
        const results = await new Promise((resolve, reject) => {
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK" && results[0]) {
              resolve(results);
            } else {
              reject(status);
            }
          });
        });

        // Get country name from address components
        const addressComponents = results[0].address_components;
        const countryComponent = addressComponents.find((component) =>
          component.types.includes("country")
        );

        // Get the full formatted address
        const formattedAddress = results[0]?.formatted_address;

        countryComponent &&
          setCenter({
            location: formattedAddress,
            lat: latitude,
            lng: longitude,
            countryName: countryComponent.long_name,
          });
        countryComponent &&
          setCurrentLocation({
            location: formattedAddress,
            lat: latitude,
            lng: longitude,
            countryName: countryComponent.long_name,
          });
        getCurrentLocation(formattedAddress, latitude, longitude);
      } catch (error) {
        console.error("Error fetching country and location:", error);
      }
    }
  };

  async function getLatLong() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition({
            lat: latitude,
            lng: longitude,
          });
        },
        (error) => {
          setCenter({
            location: LOCATION.LOC,
            lat: LOCATION.LAT,
            lng: LOCATION.LONG,
            countryName: LOCATION.COUNTRY_NAME,
          });
          console.error("Error obtaining geolocation: ", error);
        }
      );
    } else {
      setCenter({
        location: LOCATION.LOC,
        lat: LOCATION.LAT,
        lng: LOCATION.LONG,
        countryName: LOCATION.COUNTRY_NAME,
      });
    }
  }

  useEffect(() => {
    getLatLong();
  }, []);

  // function for get current location
  async function getCurrentLocation(location, lat, lng) {
    let requestData = new FormData();
    requestData.append("current_location", location);
    requestData.append("current_latitude", parseFloat(lat));
    requestData.append("current_longitude", parseFloat(lng));

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });
    const urlEncodedData = qs.stringify(dataObject);
    await SublyApi.currentLocation(userToken, urlEncodedData).then(
      (response) => {
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
        }
      }
    );
  }

  useEffect(() => {
    const selectedIds =
      selectedSubCategory && selectedSubCategory.length > 0
        ? selectedSubCategory.map((subCategory) => subCategory.id).join(",")
        : "";

    mapListShow(
      center.lat,
      center.lng,
      startDateSelect,
      selectedRange,
      endDateSelect,
      selectedIds
    );
  }, [center]);

  const handleApply = async (event, picker) => {
    const start = picker.startDate.format("YYYY-MM-DD");
    const end = picker.endDate.format("YYYY-MM-DD");
    setStartDateSelect(start);
    setEndDateSelect(end);

    if (picker.chosenLabel) {
      const days = parseInt(picker.chosenLabel.match(/\d+/));
      if (!isNaN(days)) {
        setSelectedRange(days);
      }
    }

    const selectedIds =
      selectedSubCategory && selectedSubCategory.length > 0
        ? selectedSubCategory.map((subCategory) => subCategory.id).join(",")
        : "";

    // Call mapListShow with the formatted dates
    await mapListShow(
      center.lat,
      center.lng,
      start,
      selectedRange,
      end,
      selectedIds
    );
  };

  const ranges = {
    "Last 90 Days": [moment().subtract(89, "days"), moment()],
    "Last 60 Days": [moment().subtract(59, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  const handleAddressSelect = (place) => {
    if (place.geometry) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const location = place.formatted_address;
      setSearchAddress(location);

      const countryComponent = place.address_components.find((component) =>
        component.types.includes("country")
      );

      if (countryComponent) {
        setCenter({
          location: location,
          lat: latitude,
          lng: longitude,
          countryName: countryComponent.long_name,
        });
      }

      const selectedIds =
        selectedSubCategory && selectedSubCategory.length > 0
          ? selectedSubCategory.map((subCategory) => subCategory.id).join(",")
          : "";

      mapListShow(
        latitude,
        longitude,
        startDateSelect,
        selectedRange,
        endDateSelect,
        selectedIds
      );
    }
  };

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setSearchAddress("");
      getLatLong();
    } else {
      setSearchAddress(e.target.value);
    }
  };

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  // function for get category list api calling
  async function crimeCategory() {
    await SublyApi.crimeCategoryList(userToken).then((response) => {
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setCrimesCategory(response && response.data);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  // function for get report map list api calling
  async function mapListShow(
    latitude = "",
    longitude = "",
    sdate = "",
    duration = "",
    edate = "",
    categoryId = ""
  ) {
    if (latitude === 0 || longitude === 0) {
      return; // Exit the function early if lat or lng is 0
    }
    const response = await SublyApi.getReportMapList(
      "",
      latitude,
      longitude,
      sdate,
      duration,
      edate,
      categoryId
    );

    const errormsg = response && response.data && response.data.message;
    if (response && response.status_code === STATUS_CODES.SUCCESS) {
      const reports = response && response.data && response.data.reports;
      if (reports && reports.length > 0) {
        setMapList(reports);
      } else {
        // Handle the case when no reports are found, without showing an error
        setMapList([]);
      }
    } else {
      Toster(t(errormsg), "error");
    }
  }
  useEffect(() => {
    if (center.lat !== 0 && center.lng !== 0) {
      mapListShow(center.lat, center.lng);
    }
    crimeCategory();
    handleCircleClick();
  }, []);

  const handleCircleClick = () => {
    if (currentLocation.lat && currentLocation.lng) {
      setCenter(currentLocation);
    } else {
      setCenter({
        location: LOCATION.LOC,
        lat: LOCATION.LAT,
        lng: LOCATION.LONG,
        countryName: LOCATION.COUNTRY_NAME,
      });
    }
  };

  return (
    <div className="main">
      <div
        className="scrollerFix"
        style={{
          height: `${mapHeight}px`,
        }}
      >
        <Container>
          <Row className="fullWidth">
            <Col lg={4} md={12} sm={12}>
              {" "}
              <div className={styles.location}>
                {isLoaded ? (
                  <Autocomplete
                    placeApiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY}
                    onLoad={onLoad}
                    onPlaceChanged={() => {
                      if (autoCompleteLoad !== null) {
                        const place = autoCompleteLoad.getPlace();
                        handleAddressSelect(place);
                      }
                    }}
                  >
                    <div className={styles.searchDiv}>
                      <Form.Control
                        type="text"
                        value={searchAddress}
                        placeholder={t("SEARCH_ADDRESS")}
                        onChange={handleInputChange}
                      />
                      <IoSearchSharp />
                    </div>
                  </Autocomplete>
                ) : (
                  <></>
                )}
              </div>
            </Col>

            <Col lg={4} md={12} sm={12}>
              <div className="datepickerRange">
                <DateRangePicker
                  initialSettings={{
                    startDate: startDate.toDate(),
                    endDate: endDate.toDate(),
                    ranges: ranges,
                    opens: "right",
                    alwaysShowCalendars: true,
                    maxDate: moment().toDate(),
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                  }}
                  onApply={handleApply}
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>
            </Col>

            <Col lg={4} md={12} sm={12}>
              {" "}
              <div className={`${styles.reportSelect} tooltipCls`}>
                <Button
                  ref={target}
                  onClick={(event) => {
                    setShow(!show);
                    setTarget(event.target);
                  }}
                >
                  <div className={styles.reportManage}>
                    <MdArrowDropDown />
                    <p>{t("ALL_CRIME")}</p>
                  </div>
                </Button>
                <Overlay
                  target={target}
                  show={show}
                  rootClose={true}
                  placement="bottom"
                  onHide={() => setShow(false)}
                >
                  {(props) => (
                    <Tooltip
                      id="overlay-example"
                      {...props}
                      className="tooltipSetCrime multipleCrime"
                    >
                      <MultipleCategoryModal
                        setShow={setShow}
                        startDateSelect={startDateSelect}
                        selectedRange={selectedRange}
                        endDateSelect={endDateSelect}
                        crimesCategory={crimesCategory}
                        setSelectedSubCategory={setSelectedSubCategory}
                        selectedSubCategory={selectedSubCategory}
                        mapListShow={mapListShow}
                        center={center}
                      />
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="">
          <div className={styles.googleMapSet}>
            <MapViewZoom
              center={center}
              mapContainerStyle={{
                height: `${mapHeight}px`,
                width: "100%",
                
              }}
              markers={mapList}
              isApplyZoomLevel={true}
            />

            <div
              className={styles.currentLocationButton}
              onClick={handleCircleClick}
            >
              <img
                src={CurrentLocationImg}
                style={{ width: "25px", height: "25px" }}
                alt="Current Location"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MapList;
