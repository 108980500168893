import { Col, Container, Row, Form } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./AlertLocation.module.css";
import { useTranslation } from "react-i18next";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserSwitch from "../../../commonComponent/UserSwitch";
import UserCommonButton from "../../../commonComponent/CommonButton/UserCommonButton";
import DistanceCommonSlider from "../../../commonComponent/DistanceSlider";
import InputGroups from "../../../commonComponent/InputGroup";
import { Validation } from "../../../utils/Validation";
import { handleKeyPress } from "../../../utils/manageConstant";
import Toster from "../../../utils/Toaster";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/statusCode";
import qs from "qs";
import MapReportView from "../../../commonComponent/GoogleMap/MapReportView";
import Loader from "../../../utils/Loader/Loader";
import CurrentLocation from "../../../assets/Images/Combined Shape.svg";
import { LOCATION } from "../../../utils/Constants";
import {
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";

//---function for user add/update location---------
function UpdateLocation() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
  });
  const [autoCompleteLoad, setAutoCompleteLoad] = useState(null);
  const onLoad = (autocompleteInstance) => {
    setAutoCompleteLoad(autocompleteInstance);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userToken } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [sourceGeometry, setSourceGeometry] = useState({
    location: "",
    lat: 0.0,
    lng: 0.0,
  });

  const autocomplete = useRef(null);
  const [distance, setDistance] = useState(5);
  const [unit, setUnit] = useState("km");
  const [notification, setNotification] = useState(true);
  const [emailNotify, setEmailNotify] = useState(true);
  const [locationNotify, setLocationNotify] = useState(false);
  const [loader, setLoader] = useState(false);
  const detailId = location?.state?.id?.alertLocationID;
  const AlertLocationsData = location?.state?.id;
  const isUpdate = location?.state?.isUpdate;
  const [currentPosition, setCurrentPosition] = useState("");
  const [locationSet, setLocationSet] = useState("");
  const [position, setPosition] = useState({
    lat: AlertLocationsData
      ? parseFloat(AlertLocationsData?.location_latitude)
      : 0.0,
    lng: AlertLocationsData
      ? parseFloat(AlertLocationsData?.location_longitude)
      : 0.0,
  });

  // function for handle switch change
  const handleSwitch1 = (event) => {
    setNotification(event.target.checked);
  };
  const handleSwitch2 = (event) => {
    setEmailNotify(event.target.checked);
  };

  const onMarkerDragEnd = (e) => {
    setPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const resetFormState = () => {
    setSourceGeometry("");
    setNotification(false);
    setEmailNotify(false);
    setLocationNotify(false);
    setDistance(1);
  };

  //----------function for onsubmit----------

  const onSubmit = (formData) => {
    isUpdate ? updateApi(formData) : createApi(formData);
  };

  const createApi = (formdata) => {
    let requestData = new FormData();
    requestData.append("title", formdata ? formdata.title : "");
    requestData.append(
      "address",
      sourceGeometry ? sourceGeometry.location : ""
    );
    requestData.append("latitude", sourceGeometry ? sourceGeometry.lat : "");
    requestData.append("longitude", sourceGeometry ? sourceGeometry.lng : "");
    requestData.append("push_alert_status", notification ? 1 : 0);
    requestData.append("email_alert_status", emailNotify ? 1 : 0);
    requestData.append("location_radius", distance);

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });

    const urlEncodedData = qs.stringify(dataObject);
    setLoader(true);

    SublyApi.AddAlertLoction(urlEncodedData, userToken).then((response) => {
      setLoader(false);
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t(msg), "success");
        navigate("/alert-location");
        resetFormState();
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  //----------function for update new report api calling----------
  const updateApi = (formdata) => {
    let requestData = new FormData();
    if (!sourceGeometry.location) {
      Toster(t("ADDRESS_REQUIRED"), "error");
    }
    requestData.append("title", formdata ? formdata.title : "");
    requestData.append(
      "address",
      sourceGeometry ? sourceGeometry.location : ""
    );
    requestData.append("latitude", sourceGeometry ? sourceGeometry.lat : "");
    requestData.append("longitude", sourceGeometry ? sourceGeometry.lng : "");
    requestData.append("push_alert_status", notification ? 1 : 0);
    requestData.append("email_alert_status", emailNotify ? 1 : 0);
    requestData.append("location_radius", distance);

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });

    const urlEncodedData = qs.stringify(dataObject);
    setLoader(true);

    SublyApi.UpdateAlertLoction(urlEncodedData, userToken, detailId).then(
      (response) => {
        setLoader(false);
        const msg = response && response.message;
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          Toster(t(msg), "success");
          navigate("/alert-location");
          resetFormState();
        } else if (
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "ACCOUNT_DISABLED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "SESSION_EXPIRED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
          Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
        } else {
          Toster(t(errormsg), "error");
        }
      }
    );
  };

  useEffect(() => {
    if (isUpdate && AlertLocationsData) {
      setValue("title", AlertLocationsData.location_title);
      setValue("address", AlertLocationsData.address);
      setPosition({
        lat: parseFloat(AlertLocationsData.location_latitude),
        lng: parseFloat(AlertLocationsData.location_longitude),
      });
      setSourceGeometry({
        location: AlertLocationsData.address,
        lat: parseFloat(AlertLocationsData.location_latitude),
        lng: parseFloat(AlertLocationsData.location_longitude),
      });
      setDistance(AlertLocationsData.location_radius);
      setEmailNotify(
        AlertLocationsData.location_email_alert_status == 1 ? true : false
      );
      setNotification(
        AlertLocationsData.location_push_alert_status == 1 ? true : false
      );
    }
  }, []);

  const truncateCoordinate = (value, decimalPlaces = 7) => {
    return parseFloat(value).toFixed(decimalPlaces);
  };

  //----------function for handle set error---------
  const handleAddressSelect = (place) => {
    const location = place && place.formatted_address;
    const lat = place && place.geometry.location.lat();
    const lng = place && place.geometry.location.lng();
    setPosition({ location, lat, lng });
    setSourceGeometry({ location, lat, lng });
  };

  useEffect(() => {
    // Get current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition({ lat: latitude, lng: longitude });
          setPosition({
            location: "",
            lat: latitude,
            lng: longitude,
          });

          if (userToken) {
            locationSet && getCurrentLocation(locationSet, latitude, longitude);
          }
          if (AlertLocationsData) {
            setPosition({
              location: AlertLocationsData?.address,
              lat: AlertLocationsData
                ? parseFloat(AlertLocationsData?.location_latitude)
                : latitude,
              lng: AlertLocationsData
                ? parseFloat(AlertLocationsData?.location_longitude)
                : longitude,
            });
          } else {
          }
        },
        (error) => {
          if (!AlertLocationsData) {
            setPosition({
              location: LOCATION.LOC,
              lat: LOCATION.LAT,
              lng: LOCATION.LONG,
            });
          }

          console.error("Error obtaining geolocation: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const fetchLocationName = async (lat, lng) => {
    const geocodingApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY; // Use your Google API key here
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${geocodingApiKey}`;

    const response = await fetch(geocodingUrl);
    const data = await response?.json();

    if (data.results && data.results.length > 0) {
      const formattedAddress = data.results[0].formatted_address;
      setLocationSet(formattedAddress);
      return formattedAddress;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const fetchInitialLocationName = async () => {
      const locationName = await fetchLocationName(
        position?.lat,
        position?.lng
      );
      if (locationName) {
        setSourceGeometry({
          location: locationName,
          lat: position?.lat,
          lng: position?.lng,
        });
        setValue("address", locationName);
      }
    };

    fetchInitialLocationName();
  }, [position?.lat, position?.lng]);

  // function for get current location
  async function getCurrentLocation(locationSet, lat, lng) {
    let requestData = new FormData();
    requestData.append("current_location", locationSet);
    requestData.append("current_latitude", parseFloat(lat));
    requestData.append("current_longitude", parseFloat(lng));

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });
    const urlEncodedData = qs.stringify(dataObject);
    await SublyApi.currentLocation(userToken, urlEncodedData).then(
      (response) => {
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
        }
      }
    );
  }

  // kilometer
  const convertDistance = (distance, toUnit) => {
    if (toUnit === "km") {
      return (distance * 1.60934).toFixed(2);
    } else {
      return (distance / 1.60934).toFixed(2);
    }
  };

  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    const convertedDistance = convertDistance(distance, newUnit);
    setUnit(newUnit);
    setDistance(Number(convertedDistance));
  };

  // function for change distance
  const handleChange = (event, newDistance) => {
    setDistance(newDistance);
  };

  const valueLabelFormat = (distance) => {
    const miles = (distance / 1.60934).toFixed(2);
    return unit === "km"
      ? `${distance} km (${miles} miles)`
      : `${distance} miles`;
  };

  const valueLabelFormatted = valueLabelFormat(distance);

  const handleCircleClick = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPosition({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error fetching location: ", error);
      }
    );
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main">
        <div className="spaceTopSet">
          <Container>
            <Row>
              <Col lg={3} md={4}>
                <Sidebar />
              </Col>
              <Col lg={9} md={8} className="rightPart">
                <div className="rightTopPart">
                  <h2>{t("ALERT_LOCATION")}</h2>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.alertLocation}>
                    <div className={styles.alertInfo}>
                      <InputGroups
                        name="title"
                        type="text"
                        register={register}
                        onInputChange={(e) => handleKeyPress(e, setValue)}
                        errors={errors}
                        validationRules={Validation.addressDescriptor}
                        label={t("LOCATION_DESCRIPT")}
                        placeholder={t("ENTER_NAME")}
                        maxCount={""}
                        text={""}
                        isHideGroup
                        isRequired
                      />

                      <div className={styles.location}>
                        <Form.Group className="mb-3 formCls">
                          <Form.Label className={styles.labelAdd}>
                            {t("ADDRESS")}
                            <strong>*</strong>
                          </Form.Label>
                          {isLoaded ? (
                            <Autocomplete
                              placeApiKey={
                                process.env.REACT_APP_GOOGLE_PLACE_API_KEY
                              }
                              onLoad={onLoad}
                              onPlaceChanged={() => {
                                if (autoCompleteLoad !== null) {
                                  const place = autoCompleteLoad.getPlace();
                                  handleAddressSelect(place);
                                }
                              }}
                            >
                              <Form.Control
                                name="description1"
                                type="text"
                                placeholder={t("ENTER_ADDRESS")}
                                onInputChange={(e) =>
                                  handleKeyPress(e, setValue)
                                }
                                {...register("address", Validation.address)}
                              />
                            </Autocomplete>
                          ) : (
                            <></>
                          )}
                          {errors.address && (
                            <span className="errorMsg">
                              {errors.address.message}
                            </span>
                          )}
                        </Form.Group>

                        <div className={styles.googleMapSet}>
                          <div style={{ position: "relative" }}>
                            <div
                              className={styles.currentLocationButton}
                              onClick={handleCircleClick}
                            >
                              <img
                                src={CurrentLocation}
                                style={{ width: "25px", height: "25px" }}
                                alt="Current Location"
                              />
                            </div>
                            <MapReportView
                              zoom={16}
                              setPosition={setPosition}
                              position={position}
                              onMarkerDragEnd={onMarkerDragEnd}
                              mapContainerStyle={{
                                height: "250px",
                                width: "100%",
                              }}
                            />
                          </div>
                          <div className={styles.showCenter}>
                            <h6>{t("DRAG_MAP")}</h6>
                            <p>
                              <strong>{t("LAT")} </strong>{" "}
                              {truncateCoordinate(sourceGeometry.lat)}{" "}
                              <strong>{t("LONG")} </strong>{" "}
                              {truncateCoordinate(sourceGeometry.lng)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.crimeNotify}>
                      <div className={styles.crimeNotifySpace}>
                        <h5>{t("PUSH_NOTIFY")}</h5>
                        <p>{t("RECEIVED_PUSH_NOTIFY")}</p>
                      </div>
                      <UserSwitch
                        id="custom-switch1"
                        checked={notification}
                        onChange={handleSwitch1}
                      />
                    </div>
                    <div
                      className={`${styles.crimeNotify} ${styles.crimeNotifyDispaly}`}
                    >
                      <div>
                        <h5>{t("ALERT_RADIUS")}</h5>
                        <p>{t("SELECT_DISTANCE")}</p>
                      </div>
                      <div className="switch-fieldAlert">
                        <Form.Check
                          type="radio"
                          id="radio-km"
                          name="unit"
                          value="km"
                          label="Kilometer"
                          checked={unit === "km"}
                          onChange={handleUnitChange}
                        />
                      </div>
                    </div>

                    <div className="slider-container">
                      <DistanceCommonSlider
                        min={
                          unit === "km" ? 1 : Number((1 / 1.60934).toFixed(2))
                        }
                        max={
                          unit === "km" ? 10 : Number((10 / 1.60934).toFixed(2))
                        }
                        value={distance}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                      />
                      <div className="value-display">
                        <h3>{valueLabelFormatted}</h3>
                      </div>
                    </div>

                    <div className={styles.crimeNotify}>
                      <div className={styles.crimeNotifySpace}>
                        <h5>{t("EMAIL_NOTIFY")}</h5>
                        <p>{t("ALLOW_NOTIFY")}</p>
                      </div>
                      <UserSwitch
                        id="custom-switch2"
                        checked={emailNotify}
                        onChange={handleSwitch2}
                      />
                    </div>

                    <div className={styles.crimeNotify}>
                      <UserCommonButton
                        type="button"
                        onClick={() => navigate("/alert-location")}
                        label={t("CANCEL")}
                      />
                      {isUpdate === true ? (
                        <UserCommonButton type="submit" label={t("UPDATE")} />
                      ) : (
                        <UserCommonButton type="submit" label={t("SUBMIT")} />
                      )}
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
export default UpdateLocation;
