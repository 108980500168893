import { Col, Container, Form, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./Notifications.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import UserCommonButton from "../../../commonComponent/CommonButton/UserCommonButton";
import UserSwitch from "../../../commonComponent/UserSwitch";
import DistanceCommonSlider from "../../../commonComponent/DistanceSlider";
import qs from "qs";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import {
  updatePushNotificationData,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";
import Loader from "../../../utils/Loader/Loader";

//---function for user push notifications---------
function PushNotification() {
  const { userToken, currentUser, pushNotificationData, userData } =
    useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [value, setValue] = useState(1);
  const [unit, setUnit] = useState("km");
  const [pushNotification, setPushNotification] = useState(false);
  const [suspiciousNotify, setSuspiciousNotify] = useState(false);
  const [newMessageNotify, setNewMessageNotify] = useState(false);
  const [newFollowersNotify, setNewFollowersNotify] = useState(false);
  const [nearbyCrimeNotify, setNearbyCrimeNotify] = useState(true);
  const [savedLocationNotify, setSavedLocationNotify] = useState(false);
  const [loader, setLoader] = useState(false);

  //======= function for show notification status==========
  useEffect(() => {
    setPushNotification(pushNotificationData.push_alert == "1");
    if (pushNotificationData) {
      setSuspiciousNotify(
        pushNotificationData.suspicious_activity_alert == "1"
      );
      setNewMessageNotify(pushNotificationData.new_message_alert == "1");
      setNewFollowersNotify(pushNotificationData.new_followers_alert == "1");
      setNearbyCrimeNotify(pushNotificationData.near_crime_alert == "1");
      setSavedLocationNotify(pushNotificationData.saved_location_alert == "1");
      setValue(
        pushNotificationData.near_alert_radius
          ? parseFloat(pushNotificationData.near_alert_radius)
          : 1
      );
    }
  }, []);

  // Function to update all switches based on pushNotification state
  const updateAllSwitches = (state) => {
    const alertValue = state ? 1 : 0;
    setPushNotification(alertValue);
    setSuspiciousNotify(alertValue);
    setNewMessageNotify(alertValue);
    setNewFollowersNotify(alertValue);
    setNearbyCrimeNotify(alertValue);
    setSavedLocationNotify(alertValue);
  };

  //===== function for handle cancel button=
  const handleCancel = () => {
    navigate("/dashboard");
  };

  //=== function for change distance======
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //===== function for change unit======
  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  //======= function for handle switch on/off=============
  const handleSwitch = (setter, key) => (event) => {
    const checked = event.target.checked;
    setter(checked);
  };

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  //----------function for onsubmit----------
  const onSubmit = async () => {
    const convertedValue = unit === "km" ? value : (value * 1.60934).toFixed(2);
    const pushObject = {
      suspicious_activity_alert: suspiciousNotify ? 1 : 0,
      new_message_alert: newMessageNotify ? 1 : 0,
      new_followers_alert: newFollowersNotify ? 1 : 0,
      near_alert_radius: convertedValue,
      near_crime_alert: nearbyCrimeNotify ? 1 : 0,
      saved_location_alert: savedLocationNotify ? 1 : 0,
      push_alert: pushNotification ? 1 : 0,
    };

    const urlEncodedData = qs.stringify(pushObject);
    setLoader(true);
    await SublyApi.pushNotificationStatus(
      userToken,
      urlEncodedData,
      currentUser.user_meta.pushAlertSettingID
    ).then((responsejson) => {
      setLoader(false);
      if (responsejson.status_code === STATUS_CODES.SUCCESS) {
        dispatch(
          updatePushNotificationData({
            suspicious_activity_alert: suspiciousNotify ? 1 : 0,
            new_message_alert: newMessageNotify ? 1 : 0,
            new_followers_alert: newFollowersNotify ? 1 : 0,
            near_crime_alert: nearbyCrimeNotify ? 1 : 0,
            saved_location_alert: savedLocationNotify ? 1 : 0,
            push_alert: pushNotification ? 1 : 0,
            near_alert_radius: convertedValue,
          })
        );
        Toster(responsejson.message, "success");
      } else if (
        (responsejson?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          responsejson?.data?.error_type === "ACCOUNT_DISABLED") ||
        (responsejson?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          responsejson?.data?.error_type === "SESSION_EXPIRED") ||
        (responsejson?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          responsejson?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(responsejson.data.message), "error");
      }
    });
  };

  const valueLabelFormat = (value) => {
    const miles = (value * 0.621371).toFixed(2); // Convert km to miles
    return `${value} km (${miles} miles)`;
  };

  const valueLabelFormatted = valueLabelFormat(value);

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <Sidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <h2>{t("PUSH_NOTIFY")} </h2>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.notification}>
                  <div className={styles.crimeNotify}>
                    <p>
                      {t("DOWNLOAD_NOTIFY")} -{" "}
                      <span>{t("NOTIFY_HIGHLIGHT")}</span>
                    </p>
                  </div>
                  <div className={styles.crimeNotify}>
                    <div className={styles.crimeNotifySpace}>
                      <h5>
                        {t("PUSH_NOTIFICATION_IS_ON")}&nbsp;
                        {pushNotificationData &&
                          (pushNotificationData.push_alert == 1 ? "ON" : "OFF")}
                      </h5>
                      <p>{t("SWITCH_NOTIFICATION_PARA")}</p>
                    </div>
                    <UserSwitch
                      id="custom-switch1"
                      checked={pushNotification}
                      onChange={() => updateAllSwitches(!pushNotification)}
                    />
                  </div>
                  <div className={styles.crimeNotify}>
                    <div className={styles.crimeNotifySpace}>
                      <h5>{t("ALERT_LOCATION")}</h5>
                      <p className={styles.redPara}>
                        {t("ENABLE_PUSH_NOTIFY")}{" "}
                        <span>{t("ALERT_LOCATION")}.</span>
                      </p>
                    </div>
                    <UserSwitch
                      id="custom-switch2"
                      checked={savedLocationNotify}
                      onChange={handleSwitch(
                        setSavedLocationNotify,
                        "saved_location_alert"
                      )}
                    />
                  </div>
                  <div className={styles.crimeNotify}>
                    <div className={styles.crimeNotifySpace}>
                      <h5>{t("NEW_MESSAGE")}</h5>
                      <p className={styles.redPara}>{t("NEW_MESSAGE_PARA")} </p>
                    </div>
                    <UserSwitch
                      id="custom-switch3"
                      checked={newMessageNotify}
                      onChange={handleSwitch(
                        setNewMessageNotify,
                        "new_message_alert"
                      )}
                    />
                  </div>
                  <div className={styles.crimeNotify}>
                    <div className={styles.crimeNotifySpace}>
                      <h5>{t("NEW_FOLLOWERS")}</h5>
                      <p className={styles.redPara}>{t("FOLLOWERS_PARA")} </p>
                    </div>
                    <UserSwitch
                      id="custom-switch4"
                      checked={newFollowersNotify}
                      onChange={handleSwitch(
                        setNewFollowersNotify,
                        "new_followers_alert"
                      )}
                    />
                  </div>
                  <div className={styles.crimeNotify}>
                    <div className={styles.crimeNotifySpace}>
                      <h5>
                        {t("NEARBY_CRIME")}
                        <span className="light-text"> (enable by default)</span>
                      </h5>

                      <p className={styles.redPara}>{t("RECEIVE_NOTIFY")} </p>
                    </div>
                    <UserSwitch
                      id="custom-switch5"
                      checked={nearbyCrimeNotify}
                      onChange={handleSwitch(
                        setNearbyCrimeNotify,
                        "near_crime_alert"
                      )}
                    />
                  </div>
                  <div className={styles.crimeNotify}>
                    <div className={styles.crimeNotifySpace}>
                      <h5>
                        {t("SUSPICIOUS")}{" "}
                        <span className="light-text">
                          {" "}
                          (disabled by default){" "}
                        </span>
                      </h5>
                      <p className={styles.redPara}>{t("ENABLE_NOTIFY")} </p>
                    </div>
                    <UserSwitch
                      id="custom-switch6"
                      checked={suspiciousNotify ? true : false}
                      onChange={handleSwitch(
                        setSuspiciousNotify,
                        "suspicious_activity_alert"
                      )}
                    />
                  </div>
                  <div
                    className={`${styles.crimeNotify} ${styles.crimeNotifyDispaly}`}
                  >
                    <div>
                      <h5>{t("ALERT_RADIUS")}</h5>
                      <p>{t("SELECT_DISTANCE")}</p>
                    </div>
                    <div className="switch-field">
                      <Form.Check
                        type="radio"
                        id="radio-one"
                        name="switch-one"
                        value="km"
                        label={t("KILOMETER")}
                        onChange={handleUnitChange}
                        defaultChecked
                      />
                    </div>
                  </div>

                  <DistanceCommonSlider
                    min={1}
                    max={10}
                    value={value}
                    onChange={handleChange}
                  />
                  <div className={styles.valueStyle}>
                    <h3>{valueLabelFormatted}</h3>
                  </div>
                  <div className={styles.crimeNotifyBtn}>
                    <UserCommonButton
                      type="button"
                      onClick={handleCancel}
                      label={t("CANCEL")}
                    />
                    <UserCommonButton type="submit" label={t("SUBMIT")} />
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default PushNotification;
