import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LazyLoadImageComponent = ({src,width,height,alt}) => {
    
  return (
    <div>
      <LazyLoadImage src={src}
        width={width} height={height}
        alt={alt?alt:"Image Alt"}
      />
     </div>
  )
}

export default LazyLoadImageComponent