import React, { useState } from "react";
import styles from "./Header.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Toster from "../../utils/Toaster";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";
import {
  setReportExit,
  setReportExitUrl,
  userLogoutClear,
} from "../../store/slices/Userslice";
import { updateUserData } from "../../firebase/FireBaseChat";
import DeleteConfirm from "../DeleteConfirmModal/DeleteConfirm";

const DropDown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken, userData, reportExitModal } = useSelector(
    (state) => state.user
  );
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [title, setTitle] = useState("");

  async function UpdateFirebaseUser(user, updObject) {
    await updateUserData(user.userID, updObject);
  }

  const is_terms_accepted = useSelector(
    (state) => state.user.userData.is_terms_accepted
  );

  //----- function for manage user logout-----
  const logout = async () => {
    await SublyApi.logout(userToken).then(async (response) => {
      const errormsg = response && response.data && response.data.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const updateObject = {
          online: "isOffline",
          firebase_token: "",
        };
        await UpdateFirebaseUser(userData, updateObject);

        dispatch(userLogoutClear());
        navigate("/");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  const handleClick = (event, url) => {
    event.preventDefault();
    dispatch(setReportExitUrl(url));
    if (reportExitModal == true) {
      dispatch(setReportExit(true));
    } else {
      navigate(url);
    }
  };

  // ----------- function for manage checkbox----------------
  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <div className={styles.tooltipMianDiv}>
        <div className={styles.tooltipTopBackground}></div>
        <div className={styles.tooltipUserName}>
          <h2>@{userData && userData.username}</h2>
          <h3>{userData && userData.name}</h3>
        </div>
        <hr className={styles.hrLine} />
        <div className={styles.tooltipUserReport}>
          <h1>{t("REPORTS")}</h1>
          <NavLink
            onClick={(e) => handleClick(e, "/your-report")}
            to="/your-report"
            className="userActive"
          >
            <div className={styles.dropDownCount}>
              <p>{t("YOUR_REPORT")}</p>
              <span>
                {" "}
                {userData &&
                  (userData.total_reports == "" ? 0 : userData.total_reports)}
              </span>
            </div>
          </NavLink>
          <NavLink
            onClick={(e) => handleClick(e, "/bookmark")}
            to="/bookmark"
            className="userActive"
          >
            <div className={styles.dropDownCount}>
              <p>{t("BOOKMARK")}</p>
              <span>
                {userData &&
                  (userData.bookmarked_count == ""
                    ? 0
                    : userData.bookmarked_count)}
              </span>
            </div>
          </NavLink>
          <NavLink
            onClick={(e) => handleClick(e, "/recently-viewed")}
            to="/recently-viewed"
            className="userActive"
          >
            <div className={styles.dropDownCount}>
              <p>{t("RECENTLY_VIEWED")}</p>
              <span>
                {userData &&
                  (userData.viewed_count == "" ? 0 : userData.viewed_count)}
              </span>
            </div>
          </NavLink>
          <NavLink
            onClick={(e) => handleClick(e, "/missing")}
            to="/missing"
            className="userActive"
          >
            <div className={styles.dropDownCount}>
              <p>{t("MISSING_PERSONS")}</p>
              <span>
                {userData?.total_missing_person
                  ? userData?.total_missing_person
                  : 0}
              </span>
            </div>
          </NavLink>
          <NavLink
            onClick={(e) => handleClick(e, "/wanted")}
            to="/wanted"
            className="userActive"
          >
            <div className={styles.dropDownCount}>
              <p>{t("WANTED")}</p>
              <span>
                {" "}
                {userData &&
                  (userData.total_wanted == "" ? 0 : userData.total_wanted)}
              </span>
            </div>
          </NavLink>
        </div>
        <hr className={styles.hrLine} />
        <div className={styles.tooltipUserReport}>
          <h1>{t("PROFILE")}</h1>
          <NavLink
            onClick={(e) => handleClick(e, "/profile")}
            to="/profile"
            className="userActive"
          >
            <p>{t("PROFILE")}</p>
          </NavLink>
          <NavLink
            onClick={(e) => handleClick(e, "/login-settings")}
            to="/login-settings"
            className="userActive"
          >
            <p>{t("SETTING")}</p>
          </NavLink>
          <NavLink
            onClick={(e) => handleClick(e, "/invite-friends")}
            to="/invite-friends"
            className="userActive"
          >
            <p>{t("INVITE_A_FRIEND")}</p>
          </NavLink>
          <NavLink
            onClick={(e) => handleClick(e, "/help-center")}
            to="/help-center"
            className="userActive"
          >
            <p>{t("HELP_CENTER")}</p>
          </NavLink>
        </div>
        {is_terms_accepted === 1 && (
          <div className={styles.tooltipLogout}>
            <button onClick={handleLogout}>{t("LOGOUT")}</button>
          </div>
        )}
      </div>

      <DeleteConfirm
        setShowAlertModal={setShowLogoutModal}
        showAlertModal={showLogoutModal}
        onDelete={logout}
        title={title}
      />
    </>
  );
};

export default DropDown;
