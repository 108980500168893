import Modal from "react-bootstrap/Modal";
import styles from "../YourReport/YourReport.module.css";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import Security_icon from "../../../assets/Images/Security_announcement.svg";
import { useSelector } from "react-redux";

function AlertPupupDelete({
  DeleteHandleClose,
  deleteShow,
  alertId,
  setAlertList,
}) {
  const { t } = useTranslation();

  const handleCancelAlert = () => {
    setAlertList((pre) => pre.filter((el) => el.alertID !== alertId));
    DeleteHandleClose();
  };

  return (
    <>
      <Modal
        className="mainModule"
        show={deleteShow}
        onHide={() => {
          DeleteHandleClose();
        }}
      >
        <div className="MainBox">
          <div className="header">
            <span className="alertCross">
              <RxCross2
                onClick={() => {
                  DeleteHandleClose();
                }}
              />
            </span>
          </div>
          <div className="hadingMainDiv">
            <div className={styles.securityIconDiv}>
              <span>
                <img src={Security_icon} alt="icon"/>
              </span>
            </div>

            <div className={styles.deleteHeading}>
              <h2>{t("CONFIRM_DELETE")}</h2>
              <p>{t("CONFIRM_DELETE_MESSAGE")}</p>
            </div>

            <div className={styles.deleteBtn}>
              <button
                className={styles.deleteContinue}
                onClick={() => handleCancelAlert(alertId)}
              >
                {" "}
                {t("CONTINUE")}
              </button>
              <button
                className={styles.deleteCancel}
                onClick={() => {
                  DeleteHandleClose();
                }}
              >
                {" "}
                {t("CANCEL")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AlertPupupDelete;
