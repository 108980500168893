import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import styles from "../../../appComponent/UserModule/NewReport/NewReport.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SlCalender } from "react-icons/sl";
import { FaRegClock } from "react-icons/fa";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useForm, Controller } from "react-hook-form";
import { PiCameraBold } from "react-icons/pi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import icon from "../../../assets/Images/Group.svg";
import icon1 from "../../../assets/Images/logo2.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import TextArea from "../../../commonComponent/TextAreaField";
import MapView from "../../../commonComponent/GoogleMap/MapView";
import { Validation } from "../../../utils/Validation";
import { handleKeyPress } from "../../../utils/manageConstant";
import Toster from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/statusCode";
import SublyApi from "../../../helpers/Api";
import Loader from "../../../utils/Loader/Loader";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_AS, LOCATION, REPORT_AS } from "../../../utils/Constants";
import CategoryModal from "../../UserModule/NewReport/CategoryModal";
import { ImCross } from "react-icons/im";
import { formatDate } from "../../../utils/CommonDateFormate";
import NetWorksSidebar from "../NetWorksSidebar";
import { FaLocationDot } from "react-icons/fa6";
import { TbCurrentLocation } from "react-icons/tb";
import { ImEye } from "react-icons/im";
import { ImEyeBlocked } from "react-icons/im";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.arrrowLeft} onClick={onClick}>
      <IoIosArrowBack />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.arrrowRight} onClick={onClick}>
      <IoIosArrowForward />
    </div>
  );
};

//---function for user new report---------
function NetworksYourReports() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY
  })
  const [autoCompleteLoad, setAutoCompleteLoad] = useState(null);
  const onLoad = (autocompleteInstance) => {
    setAutoCompleteLoad(autocompleteInstance);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [formatedDate, setFormatedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [formatedTime, setFormatedTime] = useState("");
  const [center, setCenter] = useState({
    location: LOCATION.LOC,
    lat: LOCATION.LAT,
    lng: LOCATION.LONG,
  });
  const [loader, setLoader] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [sourceGeometry, setSourceGeometry] = useState({
    location: "",
    lat: 0.0,
    lng: 0.0,
  });
  const { userToken, currentUser } = useSelector((state) => state.user);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [crimesCategory, setCrimesCategory] = useState([]);

  async function crimeCategory() {
    await SublyApi.crimeCategoryList(userToken).then((response) => {
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setCrimesCategory(response && response.data.categories);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    crimeCategory();
  }, []);

  // function for manage multiple image selection
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = async () => {
        const binaryData = reader.result;

        // Create a Blob from the binary data
        const blob = new Blob([binaryData], { type: file.type });

        let requestData = new FormData();
        requestData.append("type", IMAGE_AS.IMAGE);
        requestData.append("attachment", blob, file.name);
        await SublyApi.addReportImage(requestData, userToken).then(
          (response) => {
            const errormsg = response && response.data && response.data.message;
            if (response && response.status_code === STATUS_CODES.SUCCESS) {
            } else if (
              (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
                response?.data?.error_type === "ACCOUNT_DISABLED") ||
              (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
                response?.data?.error_type === "SESSION_EXPIRED") ||
              (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
                response?.data?.error_type === "INVALID_TOKEN")
            ) {
              dispatch(userLogoutClear());
              navigate("/");
              Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
            } else {
              Toster(t(errormsg), "error");
            }
          }
        );
      };
    });
  };

  //----- function for remove profile image-----
  const onImageRemove = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const handleDateChange = (date) => {
    const newDate = formatDate(date);
    setFormatedDate(newDate);
  };

  const handleTimeChange = (date) => {
    const ddate = new Date(date);
    const timeZone = moment.tz.guess();

    // Parse the date and convert it to the specified timezone
    const formattedTime = moment.tz(ddate, timeZone).format("HH:mm:ss");
    setFormatedTime(formattedTime);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const publickSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  //----------function for handle set error---------
  const handleAddressSelect = (place) => {
    const location = place.formatted_address;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setMarkerPosition({ location, lat, lng });
    setSourceGeometry({ location, lat, lng });
    setCenter({ location, lat, lng });
  };
  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  //----------function for create new report api calling----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("crime_category_id", selectedSubCategory.id);
    requestData.append(
      "location",
      sourceGeometry ? sourceGeometry.location : ""
    );
    requestData.append("city", "");
    requestData.append("state", "");
    requestData.append("country", "");
    requestData.append("latitude", sourceGeometry ? sourceGeometry.lat : "");
    requestData.append("longitude", sourceGeometry ? sourceGeometry.lng : "");
    requestData.append("field_type", "");
    requestData.append("reported_as", REPORT_AS.PROFILE);
    requestData.append("date", formatedDate ? formatedDate : "");
    requestData.append("time", formatedTime ? formatedTime : "");
    requestData.append("full_name", "");
    requestData.append("gender", "");
    requestData.append("age", "");
    requestData.append("details", formdata ? formdata.description1 : "");
    requestData.append("hash_tag", "");
    requestData.append("ethnicity", "");
    requestData.append("is_found", "");
    requestData.append("registration", "");
    requestData.append("make", "");
    requestData.append("model", "");
    requestData.append("color", "");
    selectedFiles.forEach((file, index) => {
      requestData.append("attachments", file, file.name);
    });

    requestData.append("street_number", "");
    requestData.append("street", "");
    requestData.append("postal_code", "");

    setLoader(true);
    await SublyApi.addNewReport(requestData, userToken).then((response) => {
      setLoader(false);
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t(msg), "success");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <NetWorksSidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <div className={styles.searchBoxCls}>
                  <h2>{t("EDIT_REPORT")}</h2>
                </div>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="reportIncidentOverlay">
                  <div className="reportIncident">
                    <div className="reportIncidentInner">
                      <h3>
                        <strong>{t("REPORT")} </strong>
                        {t("#72323")}
                      </h3>
                      <span>
                        Added by AdminUsername on 26 Feb 2021 at 15:33
                      </span>
                    </div>

                    <div className="reportSelect tooltipCls">
                      <Button ref={target} onClick={() => setShow(!show)}>
                        <div className="reportManage">
                          {selectedSubCategory?.name ? (
                            <p>{selectedSubCategory.name}</p>
                          ) : (
                            <p>{t("ARMED_ROBBERY")}</p>
                          )}
                          <IoIosArrowDown />
                        </div>
                      </Button>
                      <Overlay
                        target={target.current}
                        show={show}
                        placement="bottom"
                      >
                        {(props) => (
                          <Tooltip id="overlay-example" {...props}>
                            <CategoryModal
                              setShow={setShow}
                              selectedSubCategory={selectedSubCategory}
                              setSelectedSubCategory={setSelectedSubCategory}
                              crimesCategory={crimesCategory}
                            />
                          </Tooltip>
                        )}
                      </Overlay>
                    </div>

                    <div className="filterSection   ">
                      <div>
                        <h5>{t("DATE")}</h5>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="filterInputGroup"
                          >
                            <SlCalender />
                          </InputGroup.Text>
                          <div className="datepicker">
                            <Controller
                              name="validDate"
                              control={control}
                              defaultValue={null}
                              rules={Validation.dateValidate}
                              render={({ field }) => (
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => {
                                    handleDateChange(date);
                                    setStartDate(date);
                                    field.onChange(date);
                                  }}
                                  showYearDropdown
                                  showMonthDropdown
                                  changeMonth
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={100}
                                  placeholderText={t("SELECT_DATE")}
                                />
                              )}
                            />
                          </div>
                        </InputGroup>
                        {errors.validDate && (
                          <div className={styles.errorDiv}>
                            <span className="errorMsg">
                              {errors.validDate.message}
                            </span>
                          </div>
                        )}
                      </div>
                      <div>
                        <div className={styles.chekboxSet}>
                          <h5>{t("TIME")}</h5>
                          <Form.Check
                            type="checkbox"
                            id="id"
                            label={t("UNKNOWN")}
                          />
                        </div>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon2"
                            className="filterInputGroup"
                          >
                            <FaRegClock />
                          </InputGroup.Text>
                          <div className="datepicker">
                            <DatePicker
                              selected={selectedTime}
                              onChange={(date) => {
                                handleTimeChange(date);
                                setSelectedTime(date);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              dateFormat="HH:mm"
                              timeCaption="Time"
                              placeholderText={t("SELECT_TIME")}
                            />
                          </div>
                        </InputGroup>
                      </div>
                    </div>

                    <div className="location">
                      <h5>
                        <strong>{t("LOCATION")} </strong>
                        {t("OF_INCIDENT")}
                      </h5>
                      {isLoaded ? (<Autocomplete
                        placeApiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY}
                        onLoad={onLoad}
                        onPlaceChanged={() => {
                          if (autoCompleteLoad !== null) {
                            const place = autoCompleteLoad.getPlace();
                            handleAddressSelect(place);
                          }
                        }}
                      >
                        <InputGroup>
                          <InputGroup.Text className="inputGroup">
                            <FaLocationDot />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder={t("HAPPEN_TEXT")}
                            onInputChange={(e) => handleKeyPress(e, setValue)}
                            {...register("location", Validation.location)}
                          />
                        </InputGroup>
                      </Autocomplete>) : <></>}

                      <p>{t("CONFIRM_PIN")}</p>
                      {errors.location && (
                        <span className="errorMsg">
                          {errors.location.message}
                        </span>
                      )}

                      <div className="errorMessageShow">
                        <p>{t("ADDRESS_ERRORS_NETWORKS")}</p>
                      </div>

                      <div className="googleMapSet">
                        <MapView
                          center={center}
                          markerPosition={markerPosition}
                          mapContainerStyle={{
                            height: "250px",
                            width: "100%",
                          }}
                        />
                        <div className="showCenter">
                          <span>
                            <TbCurrentLocation />
                            <h6>{t("CENTRE")}</h6>
                          </span>
                          <p>
                            <strong>{t("LAT")} </strong> {sourceGeometry.lat}
                            <strong>{t("LONG")} </strong> {sourceGeometry.lng}
                          </p>
                        </div>
                      </div>
                      <div className="formCls">
                        <h5>
                          {t("INCIDENT")} <strong>{t("DETAILS")}</strong>
                        </h5>
                        <TextArea
                          name="description1"
                          placeholder={t("DESCRIBE_INCIDENT")}
                          register={register}
                          errors={errors}
                          onInputChange={(e) => handleKeyPress(e, setValue)}
                          validationRules={Validation.description1}
                        />
                        {errors.description1 && (
                          <span className="errorMsg">
                            {errors.description1.message}
                          </span>
                        )}
                        <p className="hashTag">{t("HASHTAG")}</p>
                      </div>
                      <div className="errorMessageShow">
                        <p>{t("INCIDENT_DESCRIPTION_ERRORS_NETWORKS")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="uploadSection">
                  <div className="showImages">
                    {selectedFiles.map((file, index) => {
                      const isImage = file.type.startsWith("image");
                      const isVideo = file.type.startsWith("video");
                      return (
                        <div key={index} className="showImageBox">
                          {isImage && (
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`Preview ${index}`}
                              width="100"
                              height="100"
                              onClick={() =>
                                window.open(URL.createObjectURL(file))
                              }
                            />
                          )}
                          {isVideo && (
                            <video width="100" height="100" controls>
                              <source
                                src={URL.createObjectURL(file)}
                                type={file.type}
                                onClick={() =>
                                  window.open(URL.createObjectURL(file))
                                }
                              />
                            </video>
                          )}
                          <ImCross onClick={() => onImageRemove(index)} />
                        </div>
                      );
                    })}
                  </div>
                  <input
                    accept="image/*,video/*"
                    id="file"
                    multiple
                    name="profileImage"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleImageChange(e);
                    }}
                  />
                  <label htmlFor="file" className="uploadPic">
                    <PiCameraBold /> {t("ADD_PHOTO")}
                  </label>
                </div>

                <div className="publicSliderTop">
                  <div className="reportSlider">
                    <Slider {...settings}>
                      <div className="reportInfo">
                        <div className="reportImg">
                          <img src={icon1} alt="img" />

                          <span className="sliderImageIcon">
                            <FaCheck />
                          </span>
                        </div>
                        <div className="reportText">
                          <p>{t("REPORT_AS")}</p>
                          <h3>{currentUser.name}</h3>
                        </div>
                      </div>
                      <div className="reportInfo">
                        <div className="reportImg">
                          <img src={icon} alt="img" />
                        </div>
                        <div className="reportText">
                          <p>{t("REPORT_AS")}</p>
                          <h3>{t("ANONYMS")}</h3>
                          <h6>{t("IDENTITY_WITHELD")}</h6>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="publicPvtSlider">
                  <div className="reportSlider">
                    <Slider {...publickSlider}>
                      <div className="reportInfo">
                        <div className="reportInfoRight">
                          <ImEye />
                          <h3>PUBLIC - </h3>
                          <span>
                            Visible to &nbsp;<p>ALL</p>&nbsp; CrimeSpotter
                            users.
                          </span>
                        </div>
                      </div>
                      <div className="reportInfo">
                        <div className="reportInfoRight">
                          <ImEye />
                          <h3>PUBLIC - </h3>
                          <span>
                            Visible to &nbsp;<p>ALL</p>&nbsp; CrimeSpotter
                            users.
                          </span>
                        </div>
                      </div>
                      <div className="reportInfo">
                        <div className="reportInfoRight">
                          <ImEyeBlocked />
                          <h3>PRIVATE - </h3>
                          <span>
                            Visible to Network Followers &nbsp;<p>ONLY</p>&nbsp;
                          </span>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="bottomReport">
                  <h2>
                    {t("BY_POSTING")} <strong> {t("TERMS")}</strong>
                  </h2>
                  <div className="reportButton">
                    <Button type="button" className="leftButton">
                      {t("CANCEL")}
                    </Button>
                    <Button type="submit" className="rightButton">
                      {t("SUBMIT_REPORT")}
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default NetworksYourReports;
