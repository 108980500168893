import React, { memo } from "react";
import styles from "./YourReport.module.css";
import { useNavigate } from "react-router-dom";
import LazyLoadImageComponent from "../../../utils/LazyLoading/LazyLoadImage";
import { useTranslation } from "react-i18next";
import anonymous from "../../../assets/Images/anonymous.svg";

const ReportDetailsProfile = memo(
  ({
    profileDetails,
    userId,
    reportDetails,
    REPORT_AS,
    currentUser,
    PRIVATE_POFILE,
    isFollowing,
    FollowersCount,
    reported_as
  }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const TimeLineViewProfile = (id) => {
      navigate(`/profile-detail/${id}`);
    };

    return (
      <div>
        {profileDetails?.length > 0 && (
          <div className={styles.dashboardInfo}>
            <div
              className={styles.dashboardImg}
              style={{ cursor: "pointer", marginRight: "15px" }}
              onClick={() => TimeLineViewProfile(userId)}
            >
              {reported_as == REPORT_AS.PROFILE ? (
                <LazyLoadImageComponent
                  src={
                    profileDetails &&
                    profileDetails.length > 0 &&
                    profileDetails[0].profile_picture
                  }
                  alt="profile image"
                />
              ) : (
                <>
                  <LazyLoadImageComponent
                    src={anonymous}
                    className={styles.anonymousImage}
                    alt="anonymous image"
                  />
                </>
              )}
            </div>
            {reportDetails && reportDetails.reported_as == REPORT_AS.PROFILE ? (
              <div className={styles.dashboardText}>
                <p
                  onClick={() => TimeLineViewProfile(userId)}
                  style={{ cursor: "pointer" }}
                >
                  @
                  {profileDetails &&
                    profileDetails.length > 0 &&
                    profileDetails[0].username}
                </p>
                <h3
                  onClick={() => TimeLineViewProfile(userId)}
                  style={{ cursor: "pointer" }}
                >
                  {profileDetails &&
                    profileDetails.length > 0 &&
                    profileDetails[0].name}
                </h3>

                <div className={styles.dashboardInfoBtn}>
                  {currentUser.userID !== profileDetails[0]?.userID &&
                    reported_as !==
                      PRIVATE_POFILE.reported_as && (
                      <>
                        {isFollowing ? (
                          <button
                            className={styles.followButton}
                            onClick={() => {
                              FollowersCount(userId);
                            }}
                          >
                            {t("FOLLOWING")}
                          </button>
                        ) : (
                          <button
                            className={styles.unfollowButton}
                            onClick={() => {
                              FollowersCount(userId);
                            }}
                          >
                            {t("UNFOLLOWING")}
                          </button>
                        )}
                      </>
                    )}

                  <h5>
                    <span>
                      <strong>
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].follower}
                      </strong>{" "}
                      {t("FOLLOWERS")} |{" "}
                    </span>
                    <span>
                      <strong>
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].following}
                      </strong>{" "}
                      {t("FOLLOWING")}
                    </span>
                  </h5>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.anonymousTestDetails}>
                  <h3>{t("ANONYMS")}</h3>
                  <p>{t("USER_IDENTITY_WITHELD")}</p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default ReportDetailsProfile;
