import { useSelector } from "react-redux";
import ReportDetails from "./ReportDetails";
import ReportDetailsPublic from "./ReportDetailsPublic";

function ReportDetailsShow() {
  const { currentUser } = useSelector((state) => state.user);

  return (
    <div>
      {Object.keys(currentUser)?.length === 0 ? (
        <ReportDetailsPublic />
      ) : (
        <ReportDetails />
      )}
    </div>
  );
}

export default ReportDetailsShow;
