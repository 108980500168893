import React, { useEffect } from 'react'
import styles from "../UserModule/YourReport/YourReport.module.css";
import LazyLoadImageComponent from '../../utils/LazyLoading/LazyLoadImage';
import AdBanner from "../../assets/Images/970_In-Detail.gif";

const AdBannerMain = () => {

    useEffect(() => {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {}
      }, []);
  return (
    <div className={styles.reportLeftAd}>
    <a href="https://www.in-detail.com" rel="noopener noreferrer">
      <LazyLoadImageComponent
        src={AdBanner}
        width="100%"
        height="250px"
        style={{ objectFit: "cover" }}
        alt="adBannert image"
      />
      <>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3446097307175125"
        data-ad-slot="8452272640"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </>
    </a>
  </div>
  )
}

export default AdBannerMain