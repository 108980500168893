import { FaBookmark } from "react-icons/fa";

export const DEVICE_TYPE = {
  ONE: 1,
};

export const MAX_NUMBER = {
  TEN: 10,
};

export const SOCIAL_TYPE = {
  GOOGLE: 1,
  FACEBOOK: 2,
  TWITTER: 3,
};

export const SIGNUP_TYPE = {
  NORMAL: 1,
  SOCIAL: 2,
};

export const REPORT_AS = {
  PROFILE: 1,
  ANONYMOUS: 2,
};

export const IMAGE_AS = {
  IMAGE: 1,
  VIDIO: 2,
};

export const FIELD_TYPE = {
  NONE: 0,
  GENERAL: 1,
  PERSON: 2,
  VEHICLE: 3,
  TIP_OFF: 4,
};

export const IS_FOUND = {
  CHECK: 1,
  UNCHCEK: 0,
};

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
};

export const DATE_FORMATES = {
  YY_MM_DD: "YYYY-MM-DD",
};

// -----search api -----
export let isLimit = 10;
export let offset = 0;

export const active = 1;
export const inactive = 0;

// flage report
export const FLAG_REASONS = {
  INCORRECT: 1,
  HATEFUL: 2,
  DUPLICATE: 3,
  OTHER: 4,
};

export const REASON_LABELS = {
  [FLAG_REASONS.INCORRECT]: "INCORRECT/FALSE INFO",
  [FLAG_REASONS.HATEFUL]: "HATEFUL OR HARMFUL",
  [FLAG_REASONS.DUPLICATE]: "DUPLICATED REPORT",
  [FLAG_REASONS.OTHER]: "OTHER REASON",
};

export const PRIVATE_POFILE = {
  reported_as: "2",
};
export const CONTENT_TYPE = {
  TERMS_CONDITION: 1,
  PRIVACY_POLICY: 2,
  HELP_CENTRE: 3,
  CONTACT_US: 4,
  HOW_IT_WORKS: 5,
};

export const MISSING_TYPE = {
  MISSING: 1,
  WANTED: 2,
};

export const NOTIFICATION_TYPE = {
  EMAIL: "email",
};
export const ethnicity = "";
export const gender = "";
export const age_to = "";

export const LOCATION = {
  LOC: "Cape Town City Centre",
  LAT: -33.9221632,
  LONG: 18.4229,
  COUNTRY_NAME: "South Africa",
};

export const FLAG = {
  FLAG_ADD: 1,
  FLAG_REMOVE: 0,
};


// chat br tage manage
export const ChatTextBrTageFormate = (text) => {
  if (!text) return "";
  return text.replace(/\r\n|\r|\n/g, "<br />");
};