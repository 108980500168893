import { Col, Container, Row } from "react-bootstrap";
import styles from "./YourReport.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/Loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MapView from "../../../commonComponent/GoogleMap/MapView";
import SublyApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import { FaRegCircleCheck } from "react-icons/fa6";
import {
  LOCATION,
  PRIVATE_POFILE,
  REPORT_AS,
  active,
  isLimit,
} from "../../../utils/Constants";
import { b64toBlob, getRandom } from "../../../utils/manageConstant";
import { useForm } from "react-hook-form";
import getIconByCategoryType from "../../../commonComponent/CategoryIcons";
import CropImg from "../YourProfile/CropImg";
import mime from "mime";
import {
  updateSettingCount,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import CurrentLocation from "../../../assets/Images/Combined Shape.svg";
import { Helmet } from "react-helmet";
import { useJsApiLoader } from "@react-google-maps/api";
import ReportDetailsSlider from "./ReportDetailsSlider";
import ReportDetailsProfile from "./ReportDetailsProfile";
import ReportDetailsText from "./ReportDetailsText";
import ReportDetailsImageVideo from "./ReportDetailsImageVideo";
import ReportDetailsChat from "./ReportDetailsChat";
import ReportDetailsRightMap from "./ReportDetailsRightMap";
import ReportDetailsShare from "./ReportDetailsShare";
import AdBannerRightSmall from "../AdBannerRightSmall";
import AdBannerMain from "../AdBannerMain";



<FaRegCircleCheck />;
function ReportDetails() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
  });

  const routeLocation = useLocation();
  const routeName = routeLocation.pathname;
  const { t } = useTranslation();

  // const scrollRef = useRef(null);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { userToken, currentUser } = useSelector((state) => state.user);
  const { userData } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [center, setCenter] = useState({
    location: LOCATION.LOC,
    lat: LOCATION.LAT,
    lng: LOCATION.LONG,
  });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [reportDetails, setReportDetails] = useState();
  const [profileDetails, setProfileDetails] = useState([]);

  const [isFollowing, setIsFollowing] = useState(0);
  const [commentData, setCommentData] = useState([]);
  const [commentDataList, setCommentList] = useState();
  const [comment, setComment] = useState("");
  const [userId, setUserId] = useState();
  const [offSet, setOffSet] = useState(0);
  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [reportId, setReportId] = useState("");

  const [timeLineData, setTimeLineData] = useState([]);
  const [totalReports, setTotalReports] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoThumb, setVideoThumb] = useState(null);

  const [mapList, setMapList] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const [isCropper, setIsCropper] = useState(false);

  const [deleteRederect, setDeleteRederect] = useState("");
  const [detailsWithHashtags, setDetailsWithHashtags] = useState("");
  const [reportLocation, setReportLocation] = useState(null);

  const containerRef = useRef(null);
  const handleCropComplete = () => {
    setShowCrop(false);
  };

  const IMAGE_SIZE_MB = 10;
  const VIDEO_SIZE_MB = 200;
  const MIN_IMAGE_WIDTH = 150;
  const MIN_IMAGE_HEIGHT = 150;
  const MAX_IMAGE_WIDTH = 4000;
  const MAX_IMAGE_HEIGHT = 4000;
  const VALID_VIDEO_EXTENSIONS = ["mp4", "MP4", "3gp", "3GP"];

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }
    const fileType = file.type.startsWith("video") ? "video" : "image";
    const fileData = {
      url: URL.createObjectURL(file),
      type: fileType,
      file: file,
    };

    if (fileType === "image") {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const { width, height } = img;

        if (width < MIN_IMAGE_WIDTH) {
          Toster(t("IMAGE_WIDTH_MUST_BE_150px"), "error");
          return;
        }
        if (width > MAX_IMAGE_WIDTH) {
          Toster(t("IMAGE_WIDTH_MUST_BE_4000px"), "error");
          return;
        }
        if (height < MIN_IMAGE_HEIGHT) {
          Toster(t("IMAGE_HEIGHT_MUST_BE_150px"), "error");
          return;
        }
        if (height > MAX_IMAGE_HEIGHT) {
          Toster(t("IMAGE_HEIGHT_MUST_BE_4000px"), "error");
          return;
        }

        // Check image file size
        if (file.size > IMAGE_SIZE_MB * 1024 * 1024) {
          Toster(t("IMAGE_SIZE_MUST_BE_10MB"), "error");
          return;
        }

        setIsCropper(true);
        setPreviewImage(img.src);
        setImageSrc(img.src);
        setSelectedImage(file);
        setSelectedVideo("");
        setVideoThumb("");
      };
    } else if (fileType === "video") {
      const fileExtension = file.name.split(".").pop();
      if (!VALID_VIDEO_EXTENSIONS.includes(fileExtension)) {
        Toster(t("INVALID_VIDEO_FORMAT_MP4_3GP_ONLY"), "error");
        return;
      }

      const videoElement = document.createElement("video");
      videoElement.src = fileData.url;

      videoElement.onloadedmetadata = async () => {
        // Check video dimensions
        if (videoElement.videoWidth < MIN_IMAGE_WIDTH) {
          Toster(t("VIDEO_WIDTH_MUST_BE_150px"), "error");
          return;
        }
        if (videoElement.videoWidth > MAX_IMAGE_WIDTH) {
          Toster(t("VIDEO_WIDTH_MUST_BE_4000px"), "error");
          return;
        }
        if (videoElement.videoHeight < MIN_IMAGE_HEIGHT) {
          Toster(t("VIDEO_HEIGHT_MUST_BE_150px"), "error");
          return;
        }
        if (videoElement.videoHeight > MAX_IMAGE_HEIGHT) {
          Toster(t("VIDEO_HEIGHT_MUST_BE_4000px"), "error");
          return;
        }

        // Check video file size
        if (file.size > VIDEO_SIZE_MB * 1024 * 1024) {
          Toster(t("VIDEO_SIZE_MUST_BE_200MB"), "error");
          return;
        }

        const videoThumbRes = await handleExtractFrame(fileData?.file);
        setSelectedVideo(fileData);
        setPreviewImage("");
        setImageSrc("");
        setSelectedImage("");
        setIsCropper(false);
      };
    }
  };

  useEffect(() => {
    if (isCropper) {
      setShowCrop(true);
    }
  }, [isCropper]);

  const handleClose = () => {
    setShowCrop(false);
  };

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  const [page, setPage] = useState(1);

  const loadMoreReports = () => {
    setPage((prevPage) => prevPage + 1);
    CommentList(reportDetails.reportID, page + 1, offSet + 10);
    setOffSet((prevPage) => prevPage + 10);
  };

  const handleEdit = (item) => {
    navigate("/new-report", {
      state: { id: reportDetails && reportDetails.reportID, isUpdate: true },
    });
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  // --report details
  async function getReportDetails(id) {
    setLoader(true);
    userToken &&
      (await SublyApi.reportDetails(userToken, id).then((response) => {
        setLoader(false);
        const errormsg = response && response.data && response.data.message;
        if (
          response &&
          response.data &&
          response.status_code === STATUS_CODES.SUCCESS
        ) {
          setReportDetails(response.data.report);
          setReportId(response.data.report?.reportID);
          setDeleteRederect(response?.data?.data_found);
          setCenter({
            location: response.data.report?.location,
            lat: parseFloat(response.data.report?.latitude),
            lng: parseFloat(response.data.report?.longitude),
          });
          setMarkerPosition({
            location: response.data.report?.location,
            lat: parseFloat(response.data.report?.latitude),
            lng: parseFloat(response.data.report?.longitude),
          });
          getGeocode(
            response.data.report?.latitude,
            response.data.report?.longitude
          );
        } else if (
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "ACCOUNT_DISABLED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "SESSION_EXPIRED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
          Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
        } else {
          Toster(t(errormsg), "error");
        }
      }));
  }

  useEffect(() => {
    // Check if data_found is false and navigate
    if (deleteRederect === false) {
      navigate("/your-report");
    }
  }, [deleteRederect, navigate]);

  useEffect(() => {
    if (id) {
      getReportDetails(id);
    }
  }, [id]);

  // BookmarkCount
  async function BookmarkCount(id, action) {
    setLoader(true);
    let requestData = new FormData();
    requestData.append("action", action);

    await SublyApi.UpdateBookmark(userToken, id, requestData).then(
      (response) => {
        setLoader(false);
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          getReportDetails(id);
          const successMessage =
            action === active
              ? t("REPORT_BOOKMARKED_SUCCESSFULL")
              : t("REPORT_UNBOOKMARKED_SUCCESSFULL");
          Toster(successMessage, "success");
          if (action == active) {
            dispatch(
              updateSettingCount({
                keyName: "bookmarked_count",
                value: parseInt(userData.bookmarked_count) + 1,
              })
            );
          } else {
            if (parseInt(userData.bookmarked_count) > 0) {
              dispatch(
                updateSettingCount({
                  keyName: "bookmarked_count",
                  value: parseInt(userData.bookmarked_count) - 1,
                })
              );
            }
          }
        } else if (
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "ACCOUNT_DISABLED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "SESSION_EXPIRED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
          Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
        } else {
          Toster(t(errormsg), "error");
        }
      }
    );
  }

  // TrendCount api
  async function TrendCount(id, action) {
    setLoader(true);
    let requestData = new FormData();
    requestData.append("action", action);

    await SublyApi.UpdateTrend(userToken, id, requestData).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        getReportDetails(id);
        const successMessage =
          action === active
            ? t("REPORT_TREND_SUCCESSFULL")
            : t("REPORT_UNTREND_SUCCESSFULL");
        Toster(successMessage, "success");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  // -------PublicProfile
  async function FollowersCount(followId) {
    setLoader(true);
    await SublyApi.followingUpdate(userToken, followId).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        getReportDetails(id);
        const successMessage =
          isFollowing == 1
            ? t("PROFILE_UNFOLLOW_SUCCESSFULL")
            : t("PROFILE_FOLLOW_SUCCESSFULL");
        Toster(successMessage, "success");

        if (isFollowing == 0) {
          dispatch(
            updateSettingCount({
              keyName: "following_count",
              value: parseInt(userData?.following_count) + 1,
            })
          );
        } else {
          if (parseInt(userData?.following_count) > 0) {
            dispatch(
              updateSettingCount({
                keyName: "following_count",
                value: parseInt(userData?.following_count) - 1,
              })
            );
          }
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  // -------PublicProfile
  async function PublicProfileList(id) {
    await SublyApi.PublicProfile(userToken, id).then((response) => {
      const errormsg = response && response.data && response.data.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setProfileDetails(response.data.userdetails);
        setUserId(response?.data?.userdetails[0]?.userID);
        setIsFollowing(response?.data?.userdetails[0]?.follow_by_you);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  // comment api
  async function CommentList(id, page = 1, offset = 0) {
    await SublyApi.getComment(id, isLimit, offset).then((response) => {
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response.data.comments;
        setTotalReports(response?.data?.total_reports);
        setCommentList(response.data);
        if (sortedReports && sortedReports.length > 0) {
          if (page == 1) {
            setCommentData(sortedReports);
          } else {
            setCommentData((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setCommentData([]);
        }
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [commentData]);

  // comment list
  useEffect(() => {
    if (reportDetails && reportDetails.reportID) {
      CommentList(reportDetails.reportID);
    }
  }, [reportDetails]);

  useEffect(() => {
    if (reportDetails && reportDetails.reported_by_id) {
      PublicProfileList(reportDetails.reported_by_id);
    }
  }, [reportDetails]);

  useEffect(() => {
    mapListShow();
  }, [reportDetails]);

  const getGeocode = async (latitude, longitude) => {
    const { google } = window;
    if (isLoaded && google && latitude && longitude) {
      const geocoder = new google.maps.Geocoder();
      const latLng = new google.maps.LatLng(latitude, longitude);

      try {
        const results = await new Promise((resolve, reject) => {
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK" && results[0]) {
              resolve(results);
            } else {
              reject(status);
            }
          });
        });

        const addressComponents = results[0].address_components;

        // Extracting specific address details
        const locality = addressComponents.find((component) =>
          component.types.includes("locality")
        )?.long_name;

        const region = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        const postalCode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        )?.long_name;

        const country = addressComponents.find((component) =>
          component.types.includes("country")
        )?.long_name;

        // Get the full formatted address
        const formattedAddress = results[0]?.formatted_address;

        // Set state with the extracted values
        setReportLocation({
          addressLocality: locality,
          addressRegion: region,
          postalCode: postalCode,
          addressCountry: country,
        });
      } catch (error) {
        console.error("Error fetching address details:", error);
      }
    }
  };

  // ------get #hage tag-manage--
  useEffect(() => {
    if (reportDetails && reportDetails.details) {
      const detailsWithHashtags = reportDetails.details.replace(
        /#\w+/g,
        (match) => `<span style="color: red;">${match}</span>`
      );
      reportDetails.detailsWithHashtags = detailsWithHashtags;
      setDetailsWithHashtags(detailsWithHashtags);
    }
  }, [reportDetails]);

  useEffect(() => {
    const container = containerRef.current;

    // Only add event listener if container is available
    if (container) {
      const handleSpanClick = (event) => {
        if (event.target.tagName === "SPAN") {
          const tag = event.target.innerText;
          navigate(`/search-result?tagName=${tag.replace(/#/g, "")}`);
        }
      };

      container.addEventListener("click", handleSpanClick);

      // Cleanup the event listener on component unmount
      return () => {
        container.removeEventListener("click", handleSpanClick);
      };
    }
  }, [detailsWithHashtags]);

  if (!reportDetails || !reportDetails.report_attachments) {
    return null;
  }
  const imageAttachments = reportDetails.report_attachments.filter(
    (item) => item.attachment_thumb !== null
  );
  const videoAttachments = reportDetails.report_attachments.filter(
    (item) => item.video_thumb_url !== null
  );

  const handleCommentChange = (e) => {
    const value = e.target.value;

    if (value.trim() === "") {
      setComment("");
    } else {
      setComment(value);
    }

    // setComment(e.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleExtractFrame = (videoFile) => {
    if (!videoFile) return;

    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(videoFile);
    videoElement.crossOrigin = "anonymous";
    videoElement.muted = true; // Mute the video for autoplay
    videoElement.play(); // Start playing the video to ensure metadata is loaded
    videoElement.onloadeddata = () => {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      // Ensure the video is loaded and can be drawn
      videoElement.currentTime = 0; // Set to the first frame

      videoElement.onseeked = () => {
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(async (blob) => {
          // Send the image blob to the API
          const requestData = new FormData();
          requestData.append("attachment_thumb", blob, "frame.jpg");
          setVideoThumb(requestData.get("attachment_thumb"));

          return requestData.get("attachment_thumb");
        }, "image/jpeg");
      };
    };
  };

  // PostComment
  async function chatPost() {
    let requestData = new FormData();
    requestData.append("comment", comment);

    if (isCropper == true) {
      var blockCrop = previewImage && previewImage.split(";");
      var cropContentTypes = blockCrop[0]?.split(":")[1];
      var blockRealData = blockCrop[1].split(",")[1];
      var cropBlobImg = b64toBlob(blockRealData, cropContentTypes);

      requestData.append(
        "attachment_file",
        cropBlobImg,
        getRandom() + "." + mime.getExtension(cropBlobImg.type)
      );
    } else if (selectedVideo) {
      // Append video file

      requestData.append("video_thumb", videoThumb);
      requestData.append("attachment_file", selectedVideo.file);
    }

    setLoader(true);
    await SublyApi.PostComment(
      userToken,
      reportDetails.reportID,
      requestData
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `40px`;
        getReportDetails(reportDetails.reportID);
        setComment("");
        setPreviewImage(null);
        setSelectedImage(null);
        setShowCrop(false);
        setIsCropper(false);
        handleRemoveMedia();
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  const onSubmit = (data) => {
    chatPost(data);
  };

  const handleRemoveMedia = () => {
    setPreviewImage(null);
    setSelectedImage(null);
    setShowCrop(false);
    setIsCropper(false);
    setSelectedVideo(null);
    setVideoThumb(null);
  };

  // -----map list

  async function mapListShow(
    latitude = "",
    longitude = "",
    sdate = "",
    duration = "",
    edate = "",
    categoryId = ""
  ) {
    try {
      const response = await SublyApi.getReportMapList(
        userToken,
        Number(reportDetails.latitude),
        Number(reportDetails.longitude),
        sdate,
        duration,
        edate,
        categoryId
      );

      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const reports = response && response.data && response.data.reports;
        if (reports && reports.length > 0) {
          setMapList(reports);
          setTimeLineData(reports);
        } else {
          setMapList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    } catch (error) {}
  }

  const handleCircleClick = () => {
    if (reportDetails?.reportID) {
      setCenter({
        location: reportDetails?.location,
        lat: parseFloat(reportDetails?.latitude),
        lng: parseFloat(reportDetails?.longitude),
      });
    }
  };

  // Full schema markup
  const attachmentsSchema = reportDetails?.report_attachments.map(
    (attachment) => {
      if (attachment.video_thumb_url !== null) {
        return {
          "@type": "VideoObject",
          name: attachment.attachment_name,
          contentUrl: attachment.attachment_url,
          thumbnailUrl: attachment.video_thumb_url,
        };
      } else {
        return {
          "@type": "ImageObject",
          name: attachment.attachment_name,
          contentUrl: attachment.attachment_url,
          thumbnailUrl: attachment.attachment_thumb,
        };
      }
    }
  );

  const date = reportDetails?.date;
  const time = reportDetails?.time;

  // Combine date and time into a single string in UTC format
  const dateTimeString = `${date}T${time}Z`;

  // Convert the combined string into a Date object
  const dateTime = new window.Date(dateTimeString);

  return (
    <div className="main">
      <Helmet>
        <title>Crimespotter : {reportDetails?.category_name}</title>
        <script type="application/ld+json">
          {`
          {
          "@context": "https://schema.org",
          "@type": "Report",
          "reportNumber": ${JSON.stringify(reportDetails?.reportID, null, 2)},
          "reportType": "Crime Report",
          "dateReported": ${JSON.stringify(dateTime, null, 2)},
          "locationCreated": {
            "@type": "Place",
          "address": {
            "@type": "PostalAddress",
          "streetAddress": ${JSON.stringify(reportDetails?.location, null, 2)},
           "addressLocality": ${JSON.stringify(
             reportLocation?.addressLocality,
             null,
             2
           )},
           "addressRegion": ${JSON.stringify(
             reportLocation?.addressRegion,
             null,
             2
           )},
           "postalCode": ${JSON.stringify(reportLocation?.postalCode, null, 2)},
           "addressCountry": ${JSON.stringify(
             reportLocation?.addressCountry,
             null,
             2
           )},
        },
          "geo": 
          {
            "@type": "GeoCoordinates",
            "latitude": ${JSON.stringify(reportDetails?.latitude, null, 2)},
            "longitude": ${JSON.stringify(reportDetails?.longitude, null, 2)}
          }
      },
          "description": ${JSON.stringify(reportDetails?.details, null, 2)},
          "category": 
          {
            "@type": "Thing",
            "name": ${JSON.stringify(
              reportDetails?.parent_category_name,
              null,
              2
            )},
           "identifier": ${JSON.stringify(
             reportDetails?.parent_category_id,
             null,
             2
           )}
      },
          "subCategory": {
            "@type": "Thing",
          "name": ${JSON.stringify(reportDetails?.category_name, null, 2)},
          "identifier": ${JSON.stringify(
            reportDetails?.crimeCategoryID,
            null,
            2
          )}
      },
          "reportedBy": {
            "@type": "Person",
          "name": ${JSON.stringify(reportDetails?.name, null, 2)},
          "alternateName": ${JSON.stringify(reportDetails?.username, null, 2)},
          "image": ${JSON.stringify(reportDetails?.profile_picture, null, 2)}
      },
          "reportAttachments": ${JSON.stringify(attachmentsSchema, null, 2)} ,
          "interactionStatistic": {
            "@type": "InteractionCounter",
          "interactionType": "http://schema.org/ViewAction",
          "userInteractionCount": ${JSON.stringify(
            reportDetails?.view_count,
            null,
            2
          )} 
      },
          "commentCount": ${JSON.stringify(reportDetails?.comment_count)},
          "status": ${JSON.stringify(
            reportDetails?.status == 1 ? "active" : "inactive",
            null,
            2
          )},
          "dateModified": ${JSON.stringify(reportDetails?.updated_at, null, 2)} 
        },
        {
          "@type": "Event",
          "name": ${JSON.stringify(reportDetails?.category_name, null, 2)},
          "startDate": ${JSON.stringify(dateTime, null, 2)},
          "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "location": {
            "@type": "Place",
            "name": ${JSON.stringify(reportDetails?.location, null, 2)},
            "address": {
              "@type": "PostalAddress",
              "streetAddress": ${JSON.stringify(
                reportDetails?.location,
                null,
                2
              )},
              "addressLocality": ${JSON.stringify(
                reportLocation?.addressLocality,
                null,
                2
              )},
              "addressRegion": ${JSON.stringify(
                reportLocation?.addressRegion,
                null,
                2
              )},
              "postalCode": ${JSON.stringify(
                reportLocation?.postalCode,
                null,
                2
              )},
              "addressCountry": ${JSON.stringify(
                reportLocation?.addressCountry,
                null,
                2
              )},
           }
        },
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.crimespotter.app/report/${id}"
          }

          "publisher": {
            "@type": "Organization",
            "name": "Crimespotter",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.crimespotter.app/logoimg.png"
            }
          }

          "isPartOf": {
            "@type": "CreativeWork",
            "name": "Crime Reports",
            "url": "https://www.crimespotter.app/logoimg.png"
          }
    `}
        </script>
      </Helmet>
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <div className={styles.locationreportDetails}>
            <div className={styles.googleMapSet}>
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    left: "10px",
                    bottom: "10px",
                    width: "42px",
                    height: "42px",
                    borderRadius: "50%",
                    backgroundColor: "black",
                    cursor: "pointer",
                    zIndex: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleCircleClick}
                >
                  <img
                    src={CurrentLocation}
                    style={{ width: "25px", height: "25px" }}
                    alt="Current Location"
                  />
                </div>
                <MapView
                  center={center}
                  zoom={12}
                  mapContainerStyle={{
                    height: "271px",
                    width: "100%",
                  }}
                  icon={getIconByCategoryType(reportDetails.crime_type)}
                  reportDetails={reportDetails}
                  markers={mapList}
                />
              </div>
            </div>
          </div>
          <Row>
            <Col lg={8} md={8}>
              {/*------report Text */}
              <ReportDetailsText
                reportDetails={reportDetails}
                currentUser={currentUser}
                handleEdit={handleEdit}
                containerRef={containerRef}
                detailsWithHashtags={detailsWithHashtags}
                reportDetails_field_type={reportDetails.field_type}
              />

              {/* report image and video  */}
              <ReportDetailsImageVideo
                reportDetails={reportDetails}
                imageAttachments={imageAttachments}
                videoAttachments={videoAttachments}
              />

              <ReportDetailsShare
                TrendCount={TrendCount}
                reportDetails={reportDetails}
                BookmarkCount={BookmarkCount}
                routeName={routeName}
              />

              {/* report Profile */}
              <ReportDetailsProfile
                profileDetails={profileDetails}
                userId={userId}
                reportDetails={reportDetails}
                REPORT_AS={REPORT_AS.PROFILE}
                currentUser={currentUser}
                PRIVATE_POFILE={PRIVATE_POFILE}
                isFollowing={isFollowing}
                FollowersCount={FollowersCount}
                reported_as={reportDetails.reported_as}
              />

              {/*---- Slider */}
              <ReportDetailsSlider timeLineData={timeLineData} />

              {/*----chat  */}
              <ReportDetailsChat
                commentDataList={commentDataList}
                chatContainerRef={chatContainerRef}
                currentUser={currentUser}
                commentData={commentData}
                totalReports={totalReports}
                loadMoreReports={loadMoreReports}
                previewImage={previewImage}
                selectedVideo={selectedVideo}
                handleRemoveMedia={handleRemoveMedia}
                fileInputRef={fileInputRef}
                handleFileChange={handleFileChange}
                comment={comment}
                handleCommentChange={handleCommentChange}
                textareaRef={textareaRef}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              {/*---- right map */}
              <ReportDetailsRightMap
                reportDetails={reportDetails}
                streetViewVisible={streetViewVisible}
                setStreetViewVisible={setStreetViewVisible}
              />

              <AdBannerRightSmall />
            </Col>
          </Row>
          <AdBannerMain />
        </Container>
      </div>

      <CropImg
        handleClose={handleClose}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setPreviewImage}
        aspectRatio={1}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
        onImageChange={handleCropComplete}
      />
    </div>
  );
}

export default ReportDetails;
