import styles from "../Login/Login.module.css";
import "../../assets/Styles/Common.css";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { TfiEmail } from "react-icons/tfi";
import { TfiMobile } from "react-icons/tfi";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { userLogin } from "../../store/slices/Userslice";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/statusCode";
import Loader from "../../utils/Loader/Loader";
import AuthButton from "../../commonComponent/CommonButton/AuthButton";
import AuthInput from "../../commonComponent/AuthInputs";
import { removeExtraPlusSign } from "../../utils/removeExtraPlusSigns";
import { Validation, validateNumber } from "../../utils/Validation";
import { onInputChange } from "../../utils/manageConstant";
import GoogleSocial from "../SocialComponent/GoggleSocial";
import FacebookSocial from "../SocialComponent/FacebookSocial";
import TwitterSocial from "../SocialComponent/TwitterSocialLogin";

//---------function for Login----------
function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const firbaseTokenKey = useSelector((state) => state.user.firebaseToken);

  const { isLoading } = useSelector((state) => state.user);
  const [emailActive, setEmailActive] = useState(true);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const handleChangeClick = () => {
    setEmailActive(!emailActive);
  };

  //----------function for login api calling----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    if (emailActive == true) {
      requestData.append(
        "email",
        formdata ? formdata.email && formdata.email.trim() : ""
      );
    } else {
      requestData.append(
        "dial_code",
        dialCode ? removeExtraPlusSign(dialCode) : ""
      );
      requestData.append("country_code", countryCode ? countryCode : "");
      requestData.append("phone", phoneNo ? phoneNo : "");
    }
    requestData.append(
      "password",
      formdata ? formdata.password && formdata.password.trim() : ""
    );

    requestData.append(
      "device_token",
      localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
        ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
        : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN)
    );
    dispatch(userLogin(requestData)).then((responsejson) => {
      const response = responsejson.payload;
      const errormsg = response && response.data && response.data.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        navigate("/dashboard");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  return (
    <div className="main">
      {isLoading === true ? <Loader /> : ""}
      <Container>
        <div className="topSpace">
          <div className={styles.LoginPage}>
            <h1>
              <span>{t("LOGIN")}</span> {t("WITH_EMAIL")}
            </h1>
            <h2>{t("LOGIN_USING")}</h2>
          </div>
          <div className={styles.formBlock}>
            <h5>{t("LOGIN_WITH")}</h5>
            <div className={styles.socialImages}>
              <FacebookSocial />
              <GoogleSocial />
              <TwitterSocial twitterLoginFrom={"login"} />
            </div>
            <h4>{t("OR")}</h4>
            <div className={styles.SignupText}>
              <p>{t("LOGIN_WITH")}</p>
              <div>
                <span
                  className={emailActive ? styles.TextActive : styles.EmailText}
                  onClick={() => handleChangeClick()}
                >
                  <TfiEmail />
                  {t("EMAIL")}
                </span>
                <span className={styles.border}></span>
                <span
                  onClick={() => handleChangeClick()}
                  className={
                    !emailActive ? styles.TextActive : styles.EmailText
                  }
                >
                  <TfiMobile />
                  {t("SMS")}
                </span>
              </div>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
              {emailActive ? (
                <AuthInput
                  label={t("EMAIL_ADDRESS_LOGIN")}
                  name="email"
                  type="text"
                  register={register}
                  errors={errors}
                  onInputChange={(e) => onInputChange(e, setValue)}
                  validationRules={Validation.email}
                  placeholder={t("EMAIL_ADDRESS")}
                />
              ) : (
                <AuthInput
                  label={t("PHONE_NUMBER")}
                  name="phoneNumber"
                  type="phone"
                  control={control}
                  errors={errors}
                  phoneInputProps={{
                    country: "za",
                    countryCodeEditable: false,
                    dialCode,
                    phoneNo,
                    setCountryCode,
                    setDialCode,
                    setPhoneNo,
                    clearErrors: clearErrors,
                  }}
                  validate={() => validateNumber(phoneNo)}
                />
              )}
              <AuthInput
                label={t("PASSWORD")}
                name="password"
                type="password"
                register={register}
                errors={errors}
                onInputChange={(e) => onInputChange(e, setValue)}
                validationRules={Validation.password}
                placeholder={t("ENTER_PASSWORD")}
              />
              {/* common button */}
              <AuthButton type="submit" label={t("LOGIN")} />
              <div className={styles.bottomText}>
                <NavLink to="/password-reminder">
                  {t("PASSWORD_REMIND")}
                </NavLink>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Login;
