import React, { memo } from "react";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import LazyLoadImageComponent from "../../../utils/LazyLoading/LazyLoadImage";
import ReactPlayer from "react-player";

const ReportDetailsImageVideo = memo(
  ({ reportDetails, imageAttachments, videoAttachments }) => {
    return (
      <>
        {reportDetails &&
        reportDetails.report_attachments &&
        reportDetails.report_attachments.length > 0 ? (
          <div className="imageRowMain">
            {imageAttachments &&
              imageAttachments.map((item, index) => (
                <div className="imageRowMainDiv" key={index}>
                  <LightGallery
                    speed={1000}
                    closable={true}
                    hideBarsDelay={0}
                    controls={false}
                    download={false}
                    counter={false}
                  >
                    <a href={item.attachment_thumb}>
                      <LazyLoadImageComponent
                        src={item.attachment_thumb}
                        width="100"
                        height="100"
                        style={{ cursor: "pointer" }}
                        alt="report image"
                      />
                    </a>
                  </LightGallery>
                </div>
              ))}
          </div>
        ) : (
          <div className="imageRowMain" style={{ display: "initial" }}></div>
        )}

        {videoAttachments && videoAttachments.length > 0 ? (
          <div className="VideoRowMain">
            {videoAttachments &&
              videoAttachments.map((item, index) => (
                <div className="videoContainer" key={index}>
                  <ReactPlayer
                    url={item.attachment_url}
                    controls
                    width="100%"
                    height="100%"
                  />
                </div>
              ))}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
);

export default ReportDetailsImageVideo;
