import React, { memo } from "react";
import chatMessageIcon from "../../../assets/Images/chatMessageIcon.svg";
import { useTranslation } from "react-i18next";
import styles from "./YourReport.module.css";
import LazyLoadImageComponent from "../../../utils/LazyLoading/LazyLoadImage";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import ReactPlayer from "react-player";
import { ImCross } from "react-icons/im";
import { FaChevronRight } from "react-icons/fa";
import {
  formatUpdatedDate,
  formatUpdatedTime,
} from "../../../utils/manageConstant";
import { FaCirclePlus } from "react-icons/fa6";
import { ChatTextBrTageFormate } from "../../../utils/Constants";
import { Form } from "react-bootstrap";

const ReportDetailsChat = memo(
  ({
    commentDataList,
    chatContainerRef,
    commentData,
    currentUser,
    totalReports,
    loadMoreReports,
    previewImage,
    selectedVideo,
    handleRemoveMedia,
    fileInputRef,
    handleFileChange,
    comment,
    handleCommentChange,
    textareaRef,
    handleSubmit,
    onSubmit,
  }) => {
    const { t } = useTranslation();

    return (
      <>
        {/* ----comments----- */}
        <div className="commentsMainDiv">
          <div className="commentsCountMain">
            <div className="commentsCountIcon">
              <img src={chatMessageIcon} alt="icon" />
              &nbsp; &nbsp;
            </div>

            <div className="commentsCountRight">
              <span>
                {t("COMMENTS")}&nbsp;(
                <p>
                  {commentDataList && commentDataList.total_reports
                    ? commentDataList.total_reports
                    : "0"}
                </p>
                )&nbsp;
              </span>
            </div>
          </div>

          {commentDataList && commentDataList.data_found == false ? (
            <div className="commentsDescription">
              <span>
                <h2> {t("NO_COMMENTS_YET")}</h2> {t("DID_YOU_SEE_SOMETHING")}
              </span>
              <span> {t("CAN_YOU_OFFER_INFOMATION_ON")} </span>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* left chat */}
        {commentDataList && commentDataList.data_found == true ? (
          <div className={styles.scrollTopChat} ref={chatContainerRef}>
            <div className="commTextDivNew">
              {commentData &&
                commentData
                  .slice()
                  .reverse()
                  .map((item, index) => {
                    return item?.commented_by_id != currentUser.userID ? (
                      <div className="commTextDivInner" key={index}>
                        <div className="imgIcon">
                          <LazyLoadImageComponent
                            src={item.profile_picture}
                            alt="icon"
                          />
                        </div>
                        <div className="messageIcon">
                          <div className="messageIconInner">
                            <h2>{item.comment}</h2>

                            {item &&
                            item.attachment_url &&
                            !item.video_thumb_url ? (
                              <>
                                {item && item.attachment_url ? (
                                  <div>
                                    <LightGallery
                                      speed={500}
                                      closable={true}
                                      hideBarsDelay={0}
                                      controls={false}
                                      download={false}
                                      counter={false}
                                    >
                                      <a href={item.attachment_url}>
                                        <LazyLoadImageComponent
                                          style={{ cursor: "pointer" }}
                                          src={item.attachment_url}
                                          alt="comment image"
                                        />
                                      </a>
                                    </LightGallery>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                {item &&
                                item.attachment_url &&
                                item.video_thumb_url ? (
                                  <div className={styles.videoContainerComment}>
                                    <ReactPlayer
                                      url={item.attachment_url}
                                      controls
                                      width="100%"
                                      height="100%"
                                      className="react-player"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                            <div className="messageIconShape"></div>
                          </div>
                          <p>
                            {" "}
                            @{item.username} -{" "}
                            {formatUpdatedDate(item.updated_at)}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="commTextReply" key={index}>
                        <div className="imgIcont">
                          <span></span>
                        </div>
                        <div className="commTextDivInner">
                          <div className="messageIcon">
                            <div className="messageIconInner">
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html: ChatTextBrTageFormate(item.comment),
                                }}
                              ></h2>
                              {item &&
                              item.attachment_url &&
                              !item.video_thumb_url ? (
                                <>
                                  {item && item.attachment_url ? (
                                    <div className="lightGalleryMain">
                                      <LightGallery
                                        speed={500}
                                        closable={true}
                                        hideBarsDelay={0}
                                        controls={false}
                                        download={false}
                                        counter={false}
                                      >
                                        <a href={item.attachment_url}>
                                          <LazyLoadImageComponent
                                            style={{ cursor: "pointer" }}
                                            src={item.attachment_url}
                                            alt="chat image"
                                          />
                                        </a>
                                      </LightGallery>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <>
                                  {item &&
                                  item.attachment_url &&
                                  item.video_thumb_url ? (
                                    <div
                                      className={styles.videoContainerComment}
                                    >
                                      <ReactPlayer
                                        url={item.attachment_url}
                                        controls
                                        width="100%"
                                        height="100%"
                                        className="react-player"
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}

                              <div className="messageIconShapeRight"></div>
                            </div>
                            <p>
                              @{item.username} -{" "}
                              {formatUpdatedTime(item.updated_at)}
                            </p>
                          </div>
                          <div className="imgIconRight">
                            <LazyLoadImageComponent
                              src={item.profile_picture}
                              alt="profile"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              <>
                {commentData?.length < totalReports ? (
                  <div className={styles.loadMoreSectionComment}>
                    <span onClick={loadMoreReports}>
                      {t("LOAD_MORE_COMMENTS")}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* ----comments----- */}
        <div className="commentsMainDiv">
          <div className="selectimageshow">
            {previewImage && (
              <div className="showSelectedImg">
                <div className="showSelectedImgInner">
                  {previewImage.type === "image" ? (
                    <LightGallery
                      speed={500}
                      closable={true}
                      hideBarsDelay={0}
                      controls={false}
                      download={false}
                      counter={false}
                    >
                      <a href={previewImage}>
                        <LazyLoadImageComponent
                          src={previewImage}
                          style={{ cursor: "pointer" }}
                          alt="chat previewimage"
                        />
                      </a>
                    </LightGallery>
                  ) : (
                    <LightGallery
                      speed={500}
                      closable={true}
                      hideBarsDelay={0}
                      controls={false}
                      download={false}
                      counter={false}
                    >
                      <a href={previewImage}>
                        <LazyLoadImageComponent
                          src={previewImage}
                          style={{ cursor: "pointer" }}
                          alt="chat image"
                        />
                      </a>
                    </LightGallery>
                  )}
                  <div className="imageCrossIcon">
                    <ImCross onClick={handleRemoveMedia} />
                  </div>
                </div>
              </div>
            )}

            {selectedVideo && (
              <div className="showSelectedImg">
                <div className="showSelectedImgInner">
                  {selectedVideo.type === "video" ? (
                    <ReactPlayer
                      url={selectedVideo.url}
                      controls
                      width="100%"
                      height="100%"
                      onClick={() => window.open(selectedVideo.url)}
                    />
                  ) : (
                    <ReactPlayer
                      url={selectedVideo.url}
                      controls
                      width="100%"
                      height="100%"
                      onClick={() => window.open(selectedVideo.url)}
                    />
                  )}
                  <div className="imageCrossIcon">
                    <ImCross onClick={handleRemoveMedia} />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* ---chat--input */}
          <div className="commentsInput">
            <div className="commentsInputLeft">
              <FaCirclePlus onClick={() => fileInputRef.current.click()} />
              <input
                type="file"
                accept="image/*,video/*"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e)}
              />
            </div>

            <div className="commentsInputCenter">
              {" "}
              <Form>
                <Form.Control
                  as="textarea"
                  placeholder={t("WRITE_A_COMMENT")}
                  rows={1}
                  name="comment"
                  value={comment}
                  onChange={handleCommentChange}
                  ref={textareaRef}
                  style={{
                    overflow: "auto",
                    resize: "none",
                    backgroundColor: "#efefef",
                    border: "none",
                  }}
                />
              </Form>
            </div>

            <span>
              <FaChevronRight onClick={handleSubmit(onSubmit)} />
            </span>
          </div>
        </div>
      </>
    );
  }
);

export default ReportDetailsChat;
